/* ------- Страница отзывы ------- */

.page_reviews {
    padding-bottom: 1px;
}

.paddingForReviews {
    padding-top: 84px;
    padding-bottom: 25px;
}

.review-block {
    margin-top: 25px;
    margin-bottom: 25px;
}

.review-block__hr-review {
    height: 2px;
    background-color: rgb(255, 114, 0);
    border: none;
}

.review-name__stars {
    margin-top: 5px;
}

.review-name__stars > .fa-star {
    color: #dcdcdc;
    font-size: 25px;
}

.review-name__stars.star-5 > .fa-star {
    color: rgb(255, 114, 0);
}

.review-name__stars.star-4 > .fa-star:nth-of-type(-n+4) {
    color: rgb(255, 114, 0);
}

.review-name__stars.star-3 > .fa-star:nth-of-type(-n+3) {
    color: rgb(255, 114, 0);
}

.review-name__stars.star-2 > .fa-star:nth-of-type(-n+2) {
    color: rgb(255, 114, 0);
}

.review-name__stars.star-1 > .fa-star:nth-of-type(1) {
    color: rgb(255, 114, 0);
}

.review-title-block__img {
    width: 150px;
    height: 150px;
    border-radius: 50%;
    border: 2px solid #aeadad;
    background-size: cover;
    background-position: 50% 50%;
}

.p-review-head__date {
    color: #aeadad;
    font-size: 24px;
}

.p-review-name {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-left: 35px;
    padding-top: 5px;
    padding-bottom: 5px;
}

.p-review-head {
    display: flex;
    justify-content: space-between;
    padding: 25px 15px 25px;
}

.p-review-title {
    display: flex;
}

.review-name__name {
    font-size: 24px;
    color: rgb(255, 114, 0);
}

.review-name__city {
    font-weight: 300;
    font-size: 24px;
}

.review-block__review-text {
    font-weight: 300;
    font-size: 24px;
    display: inline-block;
    overflow: hidden;
    max-height: 95px;
}

.p-review-block__more {
    display: none;
}

.p-review-block-more-wrapper {
    display: flex;
    width: 100%;
    color: rgb(255, 114, 0);
    justify-content: flex-end;
    padding: 25px 15px 0px;
    font-weight: 300;
    font-size: 24px;
}

.p-review-block-more-wrapper:hover {
    color: #aeadad;
    cursor: pointer;
}

.review-block__more-btn {
    margin-right: 15px;
}

.review-block__more-btn:hover {
    cursor: pointer;
}

.p-review-block__text-block {
    padding: 0 15px;
    position: relative;
}

.review-block__more_display {
    display: flex;
}

.review-block__more-btn_rotate {
    transform: rotate(180deg);
}

.review-block__review-text_gradient {
    box-shadow: inset 0px -100px 100px -50px #fafafa;
    opacity: 1;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
}

.review-block__review-text_autoheight {
    max-height: 1000px;
}

.review-block__review-text_gradient-none {
    opacity: 0;
}

.margin-btn-reviews {
    margin-bottom: 75px;
}

@media screen and (max-width: 560px) {
    .review-title-block__img {
        width: 75px;
        height: 75px;
    }

    .p-review-name {
        margin-left: 15px;
    }

    .review-name__name {
        font-size: 16px;
    }

    .review-name__city {
        font-size: 18px;
    }

    .review-name__stars > .fa-star {
        font-size: 18px;
    }

    .p-review-head__date {
        font-size: 16px;
    }

    .review-block__review-text {
        font-size: 16px;
    }

    .p-review-block-more-wrapper {
        font-size: 18px;
    }

    .paddingForReviews {
        padding-top: 40px;
        padding-bottom: 0px;
    }

    .margin-btn-reviews {
        margin-bottom: 25px;
    }
}

@media screen and (max-width: 375px) {
    .p-review-head {
        padding: 15px 0px 25px;
    }

    .paddingForReviews > h2 {
        font-size: 18px;
    }

    .p-review-block__text-block {
        padding: 0;
    }
}