/*--------- Оформление товара --------------*/

.page-purchase {
    padding-top: 55px;
    padding-bottom: 55px;
    padding-left: 111px;
    padding-right: 111px;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
}

.purchase-tabs-block {
    display: flex;
    flex-direction: column;
    border-radius: 10px;
    overflow: hidden;
    width: 100%;
    box-sizing: border-box;
}

.purchase-tab__head {
    display: flex;
    justify-content: space-between;
    background: #dcdcdc;
    color: #ffffff;
    padding: 15px 15px 15px 20px;
    font-size: 24px;
    text-transform: uppercase;
}

.purchase-tab {
    border: 1px solid #fafafa;
}

.purchase-tab__body {
    border: 2px solid #dcdcdc;
    display: none;
}

.purchase-tab:last-of-type .purchase-tab__body {
    border-radius: 0px 0px 10px 10px;
}

.purchase-tab__head_active {
    background: #ff7200;
}

.purchase-tab__head_success {
    background: #11de19;
}

.purchase-tab:first-of-type .purchase-tab__body {
    display: block;
}

.purchase-tab__head-icon {
    transform: translateX(100px)
}

.purchase-tab__head-icon_success {
    transform: translateX(0px)
}

.purchase-contacts-block {
    padding: 50px 80px 25px;
}

.purchase-contacts {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
}

.purchase-contacts__input {
    display: block;
    font-weight: 300;
    font-size: 22px;
    color: #888787;
    padding: 7px 40px 7px 15px;
    background: #f6f4f4;
    -webkit-appearance: none;
    border: 1px solid #dcdcdc;
    margin-bottom: 20px;
}

.purchase-contacts__input:focus {
    color: #ff7200;
    background: #ffffff;
    border: 1px solid #ff7200;
    background-position: 120%;
    background-size: 25px;
}

.purchase-contacts__input:not(:placeholder-shown) {
    background: #ffffff;
}

.purchase-contacts__input_valid:not(:placeholder-shown):invalid {
    /* clean-css ignore:start */
    background-image: url(../img/images/purchase/cancel.svg);
    /* clean-css ignore:end */
    background-repeat: no-repeat;
    background-position: 97%;
    background-size: 25px;
    border: 1px solid red;
    color: red;
}

.purchase-contacts__input_valid:focus:not(:placeholder-shown):invalid,
.purchase-contacts__input_valid:focus:not(:placeholder-shown):valid {
    /* clean-css ignore:start */
    background: url(../img/images/purchase/cancel.svg) no-repeat;
    /* clean-css ignore:end */
    color: #ff7200;
    background: #ffffff;
    border: 1px solid #ff7200;
    background-position: 120%;
    background-size: 25px;
}

.purchase-contacts__input_valid:not(:placeholder-shown):valid {
    /* clean-css ignore:start */
    background-image: url(../img/images/purchase/checked.svg);
    /* clean-css ignore:end */
    background-color: #ffffff;
    color: #6ac358;
    background-repeat: no-repeat;
    background-position: 97%;
    background-size: 25px;
    border: 1px solid #6ac358;
}

.purchase-contacts__label {
    display: block;
    padding-left: 15px;
    font-weight: 300;
    font-size: 22px;
    color: #292929;
    margin-bottom: 5px;
}

.purchase-contacts__warning {
    font-weight: 300;
    font-size: 20px;
    color: #747373;
    text-align: center;
    display: block;
    margin-bottom: 20px;
}

.purchase-contacts__nav-btn {
    color: #fefefe;
    font-size: 22px;
    background: #ff7200;
    padding: 15px 35px;
    border-radius: 100px;
    border: none;
    text-transform: uppercase;
    line-height: 1;
    margin: 5px;
}

.purchase-contacts__nav-btn:hover {
    cursor: pointer;
    background: #ff9946;
}

.purchase-contacts__next-block {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.purchase-contacts__nav {
    display: flex;
}

.purchase-info-block {
    padding: 50px 80px 25px;
}

#purchase-info__text-area {
    width: 100%;
    box-sizing: border-box;
    font-weight: 300;
    font-size: 22px;
    color: #888787;
    padding: 7px 15px;
    background: #f6f4f4;
    -webkit-appearance: none;
    border: 1px solid #dcdcdc;
    margin-bottom: 25px;
}

#purchase-info__text-area:focus {
    color: #ff7200;
    background: #ffffff;
    border: 1px solid #ff7200;
}

#purchase-info__text-area:not(:placeholder-shown) {
    background: #ffffff;
}

.purchase-info__note {
    text-align: center;
    font-weight: 300;
    font-size: 20px;
    color: #292929;
    margin-bottom: 15px;
}

.purchase-info__label {
    display: block;
    font-weight: 300;
    font-size: 22px;
    color: #292929;
    margin-bottom: 15px;
}

.purchase-info__file-block {
    margin-bottom: 15px;
}

#purchase-info__file-send {
    display: none;
}

#purchase-info__file-label {
    cursor: pointer;
}

.purchase-info__file-label > .fa-plus {
    color: #aeadad;
}

.purchase-info__file-label {
    display: inline-block;
    background-color: #f6f4f4;
    padding: 15px;
    color: #747373;
    font-size: 20px;
    border: 1px solid #dcdcdc;
    border-radius: 5px;
    cursor: pointer;
}

.purchase-info__file-label:hover {
    border: 1px solid #ff7200;
    background-color: #ffffff;
}

.required {
    font-family: Arial;
    color: #ff7200;
}

.purchase-pay-block {
    padding-bottom: 25px;
}

.purchase-delivery__delivery {
    display: none;
}

.purchase-delivery__delivery-label {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    margin-left: 15px;
    margin-right: 15px;
    width: 18px;
    height: 18px;
    background-color: #ffffff;
    border-radius: 50%;
    border: 1px solid #b0afaf;
    box-sizing: border-box;
    flex: 0 0 auto;
}

.purchase-delivery__delivery + .purchase-delivery__delivery-label > span {
    display: block;
    background-color: #ffffff;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    cursor: pointer;
}

.purchase-delivery__delivery:checked + .purchase-delivery__delivery-label > span {
    background-color: #ff7200;
}

.purchase-delivery__radio {
    display: flex;
    margin-left: 45px;
    font-weight: 300;
    font-size: 20px;
    color: #292929;
    align-items: center;
}

.purchase-pay__method-img {
    height: 100%;
    max-height: 60px;
    margin-top: 15px;
    margin-bottom: 15px;
}

.purchase-delivery__block-name {
    display: block;
    margin-top: 35px;
    margin-bottom: 20px;
    margin-left: 30px;
    font-weight: 300;
    font-size: 22px;
    color: #292929;
}

.purchase-pay {
    padding-left: 7px;
    padding-right: 7px;
    box-sizing: border-box;
    margin-bottom: 25px;
}

.purchase-pay__pay {
    display: none;
}

.purchase-pay__method {
    width: 100%;
    box-sizing: border-box;
    border: 2px solid transparent;
    border-radius: 5px;
    margin: 0;
    text-align: center;
    font-weight: 300;
    font-size: 18px;
    color: #292929;
    line-height: 1.2;
}

.purchase-pay__method:hover,
.purchase-pay__pay:checked + .purchase-pay__pay-label .purchase-pay__method {
    border: 2px solid #ff7200;
    box-shadow: 0px 0px 25px -5px #292929;
    cursor: pointer;
    color: #ff7200;
}

.purchase-pay__container {
    display: flex;
}

.purchase-pay__pay-label {
    display: flex;
    width: calc(16.6% - 14px);
    margin-left: 7px;
    margin-right: 7px;
    float: left;
}

.purchase-pay-price {
    margin-left: 35px;
    margin-bottom: 20px;
}

.purchase-pay-price__title {
    font-weight: 300;
    font-size: 22px;
    color: #292929;
    margin-right: 30px;
}

.purchase-pay-price__total {
    font-size: 30px;
    color: #ff7200;
}

.purchase-confirm {
    padding: 15px;
    margin-bottom: 15px;
    font-size: 16px;
    color: #292929;
    font-weight: 300;
    text-align: center;
}

@media screen and (max-width: 1169px) {
    .purchase-contacts-block {
        padding: 25px 50px 13px;
    }

    .purchase-info-block {
        padding: 25px 50px 13px;
    }

}

@media screen and (max-width: 959px) {
    .page-purchase {
        padding: 40px 55px;
    }

    .purchase-contacts__left,
    .purchase-contacts__right {
        width: 45%;
        box-sizing: border-box;
    }

    .purchase-contacts__input {
        width: 100%;
        box-sizing: border-box;
    }

    .purchase-pay__pay-label {
        width: calc(25% - 14px);
    }

}

@media screen and (max-width: 700px) {
    .purchase-contacts {
        flex-direction: column;
    }

    .purchase-contacts__left,
    .purchase-contacts__right {
        width: 100%;
    }
}

@media screen and (max-width: 600px) {
    .page-purchase {
        padding: 40px 15px;
    }

    .purchase-tab__head {
        font-size: 14px;
    }

    .purchase-contacts-block {
        padding: 25px 35px 15px;
    }

    .purchase-contacts__label {
        font-size: 16px;
    }

    .purchase-contacts__input {
        font-size: 16px;
        margin-bottom: 15px;
    }

    .purchase-contacts__warning {
        font-size: 14px;
        margin-bottom: 10px;
    }

    .purchase-contacts__nav-btn {
        font-size: 16px;
        padding: 10px 20px;
    }

    .purchase-info-block {
        padding: 20px 25px 10px;
    }

    .purchase-info__note {
        font-size: 14px;
    }

    .purchase-info__label {
        font-size: 16px;
    }

    #purchase-info__text-area {
        font-size: 16px;
    }

    .purchase-info__file-label {
        font-size: 16px;
        padding: 10px;
    }

    .purchase-delivery__block-name {
        font-size: 16px;
        margin-top: 20px;
        margin-left: 15px;
        margin-bottom: 10px;
    }

    .purchase-delivery__radio {
        margin-left: 0;
        font-size: 16px;
    }

    .purchase-pay__method {
        font-size: 16px;
    }

    .purchase-pay__pay-label {
        width: calc(33.3% - 14px);
    }
}

@media screen and (max-width: 414px) {
    .purchase-tab__head {
        padding: 13px 5px 10px 13px;
    }

    .purchase-contacts-block {
        padding: 20px 25px 10px;
    }

    .purchase-contacts__nav-btn {
        font-size: 14px;
        padding: 8px 16px;
    }

    .purchase-info-block {
        padding: 20px 20px 10px;
    }

    #purchase-info__text-area {
        font-size: 14px;
    }

    .purchase-info__file-label {
        font-size: 14px;
        padding: 10px;
    }

    .purchase-delivery__block-name {
        font-size: 16px;
        margin-top: 20px;
        margin-left: 15px;
        margin-bottom: 10px;
    }

    .purchase-pay__method {
        font-size: 14px;
    }

    .purchase-pay__pay-label {
        width: calc(33.3% - 8px);
    }

    .purchase-pay-price__title {
        font-size: 18px;
    }

    .purchase-pay-price__total {
        font-size: 20px;
    }

    .purchase-contacts__input_valid:not(:placeholder-shown):valid {
        background-size: 20px;
    }

    .purchase-contacts__input_valid:not(:placeholder-shown):invalid {
        background-size: 20px;
    }

    .purchase-contacts__input {
        padding: 7px 30px 7px 15px;
    }

    .purchase-confirm {
        font-size: 14px;
    }
}

.purchase-delivery__delivery-tk {
    display: block;
    font-weight: 300;
    font-size: 22px;
    color: #888787;
    padding: 7px 40px 7px 15px;
    background: #f6f4f4;
    -webkit-appearance: none;
    border: 1px solid #dcdcdc;
    margin-bottom: 20px;
    margin-left: 95px;
}

.purchase-delivery__delivery-description {
    margin-left: 95px;
    margin-bottom: 15px;
}

@media screen and (max-width: 959px) {
    .purchase-delivery__delivery-tk {
        box-sizing: border-box;
    }
}

@media screen and (max-width: 600px) {
    .purchase-delivery__delivery-tk {
        font-size: 16px;
        margin-bottom: 15px;
        margin-left: 50px;
    }

    .purchase-delivery__delivery-description {
        margin-left: 50px;
    }
}

@media screen and (max-width: 414px) {
    .purchase-delivery__delivery-tk {
        padding: 7px 30px 7px 15px;
    }
}


.sale-done {
    font-weight: 700;
    font-size: 30px;
    color: #292929;
    text-align: center;
    padding-top: 30px;
}

.sale-done__order-number {
    font-weight: 800;
    color: #ff7200;
}

.pay-method {
    display: flex;
    justify-content: center;
}

.pay-method .pay_name, .pay-method .paysystem_name {
    font-weight: 400;
    font-size: 25px;
    color: #292929;
    text-align: center;
    margin-top: 15px;
}

.order-done {
    display: flex;
    justify-content: center;
    margin: 20px 0;
}

.order-done__cart-icon {
    font-size: 150px;
    color: #dcdcdc;
    margin: 0 30px 15px 30px;
}

.order-done__check-icon {
    position: absolute;
    bottom: 0;
    right: 0;
    font-size: 75px;
    color: #00b000;
}

.order-done__wrapper {
    position: relative;
}

.order-done-notice {
    font-weight: 300;
    color: #292929;
    text-align: center;
    font-size: 16px;
    margin-bottom: 15px;
}

.order-done-notes {
    padding-bottom: 30px;
}

@media screen and (max-width: 767px) {
    .pay-method .pay_name, .pay-method .paysystem_name {
        font-size: 16px;
    }

    .order-done-notice {
        font-size: 14px;
    }

    .sale-done {
        font-size: 18px;
    }
}
