/* ------ Пагинация ------*/

.pagination {
    display: flex;
    justify-content: center;
}

.pagination ul {
    list-style: none;
    display: flex;
    color: rgb(255, 114, 0);
}

.pagination__page {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50px;
    height: 50px;
    font-weight: 300;
    font-size: 30px;
    border: 2px solid transparent;
    margin-right: 2px;
    margin-left: 2px;
    cursor: pointer;
}

.pagination__page:hover {
    border: 2px solid rgb(255, 114, 0);
}

.pagination__page_active {
    background-color: rgb(255, 114, 0);
    border: 2px solid rgb(255, 114, 0);
    color: #ffffff;
    cursor: default;
}

.news-pagination-container {
    margin-bottom: 70px;
}

@media screen and (max-width: 959px) {
    .pagination__page {
        width: 40px;
        height: 40px;
        font-size: 22px;
    }
}

@media screen and (max-width: 560px) {
    .pagination__page {
        width: 30px;
        height: 30px;
        font-size: 18px;
    }
}

/* ------- Пагинация уменьшенная ---------*/

.pagination__page_small {
    width: 40px;
    height: 40px;
    font-size: 22px;
}