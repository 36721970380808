.btnFullWidth {
    padding-left: 15px;
    padding-right: 15px;
}

.btnFullWidth a span {
    display: inline-block;
    font-size: 35px;
}

.btnFullWidth a {
    display: flex;
    height: 75px;
    width: 100%;
    color: #ffffff;
    text-transform: uppercase;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    background: linear-gradient(rgba(255, 255, 255, .1) -150%, rgb(255, 114, 0) 50%, rgba(0, 0, 0, .5) 300%);
    box-shadow: 0px 10px 53px -8px rgba(122, 122, 122, 0.71);
}

.btnFullWidth a:hover {
    background: linear-gradient(rgba(255, 255, 255, .1) -110%, rgb(255, 114, 0) 50%, rgba(0, 0, 0, .7) 300%);
}

@media screen and (max-width: 1169px) {


    .btnFullWidth a span {
        font-size: 25px;
    }

    .btnFullWidth a {
        height: 60px;
    }

}

@media screen and (max-width: 400px) {

    .btnFullWidth a span {
        font-size: 20px;
    }
}