.mainOurWorksContainer {
    display: flex;
}

.mainOurWorks {
    width: 33.33%;
    padding: 15px;
    overflow: hidden;
}

.mainOurWorks__mainBlock {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    cursor: pointer;
}

.mainOurWorks__img {
    width: 100%;
    display: block;
}

.mainOurWorks__imgWrapper {
    width: 100%;
    overflow: hidden;
}

.mainOurWorks__mainBlock:hover .mainOurWorks__img {
    filter: blur(2px);
}

.mainOurWorks__line1,
.mainOurWorks__line2,
.mainOurWorks__line3,
.mainOurWorks__line4 {
    background-color: rgb(255, 114, 0);
    width: 30%;
    height: 2px;
    position: absolute;
}

.mainOurWorks__line1 {
    top: 6%;
    right: 9%;
}

.mainOurWorks__line2 {
    bottom: 6%;
    left: 9%;
}

.mainOurWorks__title {
    position: absolute;
    width: 100%;
    min-height: 70px;
}

.mainOurWorks__title {
    text-align: center;
    display: flex;
    justify-content: center;
}

.mainOurWorks__name {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 70%;
    color: #ffffff;
    font-size: 22px;
    font-weight: 700;
    text-transform: uppercase;
}

.mainOurWorks__line3 {
    position: absolute;
    top: -10px;
}

.mainOurWorks__line4 {
    position: absolute;
    bottom: -10px;
}

.mainOurWorks__line3 {
    transform: translate(300%, 0);
}

.mainOurWorks__line4 {
    transform: translate(-300%, 0);
}

.mainOurWorks__mainBlock:hover .mainOurWorks__line1 {
    transform: translate(150%, 0);
}

.mainOurWorks__mainBlock:hover .mainOurWorks__line2 {
    transform: translate(-150%, 0);
}

.mainOurWorks__mainBlock:hover .mainOurWorks__line3 {
    transform: translate(0, 0);
}

.mainOurWorks__mainBlock:hover .mainOurWorks__line4 {
    transform: translate(0, 0);
}

.mainOurWorks__gradient {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
}

.mainOurWorks__mainBlock:hover .mainOurWorks__gradient {
    background-color: rgba(0, 0, 0, 0.5) !important;
}

.mainOurWorks__more {
    position: absolute;
    bottom: 15%;
    font-size: 22px;
    font-weight: 300;
    opacity: 0;
}

.mainOurWorks__mainBlock:hover .mainOurWorks__more {
    opacity: 1;
}

@media screen and (max-width: 1169px) {

    .mainOurWorks__name {
        font-size: 18px;
    }
}