/* ------- Детальная новость -------- */

.news-detail-date {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-left: 15px;
  padding-right: 15px;
  height: 100px;
}

.news-detail-date__date {
  display: inline-block;
  font-size: 22px;
  color: #aeadad;
  border-bottom: 2px solid #aeadad;
  padding-left: 25px;
  padding-right: 25px;
  padding-bottom: 5px;
}

.news-detail-title__title {
  color: rgb(255, 114, 0);
  font-size: 30px;
  font-weight: 700;

}

.news-detail-title {
  display: flex;
  padding-left: 15px;
  padding-right: 15px;
  flex-direction: column;
  margin-bottom: 20px;
}

.news-detail-title__border {
  display: block;
  align-self: flex-end;
  width: 50%;
  margin-top: 15px;
  height: 2px;
  background-color: rgb(255, 114, 0);
}

.news-detail-text-area {
  padding-left: 15px;
  padding-right: 15px;
  font-weight: 300;
}

.news-detail-text-area .img-block {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.news-detail-text-area .p {
  margin-top: 10px;
  margin-bottom: 20px;
  font-size: 26px;
  color: black;
}

.news-detail-text-area .img-block > a {
  margin: 25px 15px;
  box-sizing: border-box;
}

.news-detail-text-area .img-block > a > img {
  display: block;
  border: 1px solid #d6d6d6;
  border-radius: 10px;
  box-sizing: border-box;
  width: 100%;
}

.news-detail-text-area .img20 {
  width: calc(20% - 30px);
}

.news-detail-text-area .img25 {
  width: calc(25% - 30px);
}

.news-detail-text-area .img33 {
  width: calc(33.3% - 30px);
}

.news-detail-text-area .img50 {
  width: calc(50% - 30px);
}

.news-detail-text-area .img75 {
  width: calc(75% - 30px);
}

.news-detail-text-area .img100 {
  width: calc(100% - 30px);
}

.news-detail-text-area .h1 .news-detail-text-area .h2,
.news-detail-text-area .h3,
.news-detail-text-area a {
  color: rgb(255, 114, 0);
}

.news-detail-text-area a:hover {
  color: black;
}

.news-detail-text-area .h3 {
  font-weight: 400;
  text-align: center;
  font-size: 30px;
  margin-top: 28px;
  margin-bottom: 15px;
}

.news-detail-text-area .h4 {
  display: inline-block;
  border-bottom: 1px solid black;
  font-size: 24px;
  margin-top: 25px;
}

.news-detail-text-area ul {
  padding-left: 25px;
  margin-top: 5px;
  margin-bottom: 30px;
}

.news-detail-text-area li {
  color: rgb(255, 114, 0);
  font-size: 25px;
  margin-bottom: 5px;
}

.news-detail-text-area li > span {
  display: inline-block;
  color: black;
  font-size: 26px;
}

.detail-news-slider-fix {
  height: 60px;
}

.article-list-with-icon {
  display: flex;
  flex-direction: column;
  font-size: 26px;
  color: black;
  margin: 20px 0 30px;
}

.article-list-with-icon__item {
  display: flex;
  margin-bottom: 10px;
}

.article-list-with-icon__icon {
  display: flex;
  justify-content: center;
  margin-right: 10px;
  min-width: 30px;
  width: 30px;
}

@media screen and (max-width: 560px) {
  .news-detail-text-area .p {
    font-size: 18px;
  }

  .news-detail-text-area .h3 {
    font-size: 22px;
  }

  .news-detail-text-area .h4 {
    font-size: 18px;
  }

  .news-detail-text-area li > span {
    font-size: 18px;
    display: flex;
  }

  .news-detail-text-area .img25 {
    width: calc(50% - 30px);
  }

  .news-detail-text-area .img75 {
    width: calc(100% - 30px);
  }

  .article-list-with-icon {
    font-size: 18px;
  }
}
