.calc-item {
  display: flex;
  width: 100%;
  max-width: 700px;
}

.calc-item:not(:last-of-type) {
  padding: 20px 0;
  border-bottom: 2px solid #dcdcdc;
}

.calc-item:last-of-type {
  padding-top: 20px;
}

.calc-item__img-wrapper {
  flex-shrink: 0;
  width: 100px;
  height: 100px;
  border-radius: 7px;
  overflow: hidden;
  margin-right: 30px;
}

.calc-item__img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.calc-item__inner {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-grow: 1;
}

.calc-item__name {
  font-size: 26px;
  font-weight: 400;
  margin-right: 15px;
}

.calc-item__btn {
  font-size: 20px;
  background: #ff7200;
  color: #fff;
  padding: 13px 20px;
  border-radius: 7px;
  font-weight: 400;
  white-space: nowrap;
}

@media screen and (max-width: 767px) {
  .calc-item__img-wrapper {
    width: 75px;
    height: 75px;
  }

  .calc-item__inner {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    flex-grow: 1;
  }

  .calc-item__name {
    font-size: 18px;
    font-weight: 400;
    margin: 0 0 10px 0;
  }

  .calc-item__btn {
    font-size: 16px;
    padding: 7px 10px;
    border-radius: 4px;
    font-weight: 400;
    white-space: nowrap;
  }
}
