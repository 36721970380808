/*------- Поиск --------*/

.search-page {
    padding: 40px 0 105px;
}

.search-page-form-block {
    display: flex;
    justify-content: center;
    margin-bottom: 40px;
}

.search-page-form {
    display: flex;
}

.search-page-form__input {
    background: #ff7200;
    font-size: 24px;
    color: #ffffff;
    padding: 7px 15px;
    border: none;
    border-radius: 7px 0 0 7px;
    margin: 0;
}

.search-page-form__input::placeholder {
    color: #ffb06f;
}

.search-page-submit {
    margin: 0;
    padding: 7px 25px;
    font-size: 24px;
    background: #ff7200;
    border: none;
    border-radius: 0 7px 7px 0;
    /* clean-css ignore:start */
    background-image: url(../img/images/search/searcher.svg);
    /* clean-css ignore:end */
    background-size: 25px;
    background-repeat: no-repeat;
    background-position: 50%;
    cursor: pointer;
}

.search-page-title {
    font-weight: 300;
    font-size: 24px;
    color: #292929;
    text-transform: uppercase;
    text-align: center;
}

.search-result {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    padding-top: 30px;

}

.search-item {
    display: flex;
    width: calc(50% - 30px);
    box-sizing: border-box;
    padding: 30px 40px;
    border: 2px solid #dcdcdc;
    border-radius: 7px;
    background: #fafafa;
    margin-top: 15px;
    margin-bottom: 15px;
}

.search-item:hover {
    border: 2px solid #ff7200;
}

.search-item__img {
    display: block;
    object-fit: cover;
    width: 150px;
    height: 150px;
    box-sizing: border-box;
    border: 2px solid #dcdcdc;
    border-radius: 4px;
    margin-right: 35px;
}

.search-item__content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.search-item__title {
    display: block;
    font-size: 20px;
    color: #292929;
    text-transform: uppercase;
}

.search-item__text {
    font-weight: 300;
    font-size: 20px;
    color: #aeadad;
    cursor: pointer;
}

.search-item__link {
    display: block;
    text-align: right;
    font-size: 16px;
}

.search-item__link:hover {
    color: #ff9946;
}

@media screen and (max-width: 1169px) {
    .search-item {
        padding: 20px 30px;
    }

    .search-item__img {
        width: 120px;
        height: 120px;
    }

    .search-item__title {
        font-size: 18px;
    }

    .search-item__text {
        font-size: 17px;
    }
}

@media screen and (max-width: 959px) {
    .search-page-title {
        font-size: 22px;
    }

    .search-item {
        padding: 10px 20px;
    }

    .search-item__img {
        width: 90px;
        height: 90px;
    }

    .search-item__title {
        font-size: 14px;
    }

    .search-item__text {
        font-size: 14px;
    }

    .search-item__link {
        font-size: 14px;
    }
}

@media screen and (max-width: 767px) {
    .search-page {
        padding-bottom: 25px;
    }

    .search-page-form-block {
        margin-bottom: 20px;
    }

    .search-result {
        padding-top: 15px;
    }

    .search-item {
        width: calc(100% - 30px);
        padding: 10px 10px;
        margin-top: 0;
        margin-bottom: 15px;
    }

    .search-item__img {
        width: 70px;
        height: 70px;
        margin-right: 10px;
    }

    .search-item__title {
        font-size: 14px;
        margin-bottom: 5px;
    }

    .search-item__text {
        font-size: 13px;
        margin-bottom: 5px;
    }

    .search-item__link {
        font-size: 12px;
    }

    .search-page-form__input {
        font-size: 16px;
        padding: 5px 10px;
    }

    .search-page-submit {
        padding: 7px 25px;
        font-size: 16px;
        background-size: 18px;
    }

    .contacts-main {
        flex-direction: column;
    }

    .contacts-main-left,
    .contacts-main-right {
        width: 100%;
    }

    .contact__title {
        font-size: 14px;
    }

    .contacts__title {
        font-size: 16px;
    }

    .contact__text {
        font-size: 12px;
    }

    .contact__icon {
        width: 50px;
        font-size: 35px;
    }

    .contacts-main-left {
        margin-top: 30px;
    }

    .contact, .contact-50w {
        margin-bottom: 20px;
    }

    .contacts-main-right {
        margin-top: 20px;
    }

    .contacts-social {
        width: 30px;
        height: 30px;
        font-size: 16px;
    }

    .contacts-main-right .feedbackForm {
        width: 90%;
        margin: auto;
    }
}