.slick-dots {
  display: flex;
  list-style: none;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
}

.slick-dots li button {
  border-radius: 50%;
  appearance: none;
  width: 18px;
  height: 18px;
  background: rgb(220, 220, 220);
  border: none;
  font-size: 0;
  margin: 0;
  padding: 0;
  color: transparent;
  cursor: pointer;
}

.slick-dots li:not(:last-of-type) button {
  margin-right: 10px;
}

.mainSlider_block .slick-dots li.slick-active button {
  opacity: 1;
  background: rgb(255, 114, 0);
}

.slick-dots li.slick-active button {
  opacity: 1;
  background: rgb(255, 114, 0);
}

.slick-dots li button:hover {
  background: rgb(255, 114, 0);
}

.slick-prev,
.slick-next {
  font-size: 0;
  line-height: 0;
  position: absolute;
  top: 50%;
  display: block;
  width: 20px;
  height: 20px;
  padding: 0;
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
  cursor: pointer;
  color: transparent;
  border: none;
  outline: none;
  background: transparent;
}

@media screen and (max-width: 400px) {
  .slick-dots li button {
    width: 12px;
    height: 12px;
  }

  .slick-dots li:not(:last-of-type) button {
    margin-right: 7px;
  }
}

.page_index .slick-dots {
  bottom: -40px;
}

.fourProductItems_dp-more-products .slick-list {
  width: 100%;
}
