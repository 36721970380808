/* ------------ Детальная страница товара --------------- */

.page-detail-product {
    display: flex;
    width: 100%;
}

.detail-product {
    width: 75%;
}

.sidebar-category {
    width: 25%;
    margin-top: 58px;
    padding-right: 15px;
    box-sizing: border-box;
}

.sidebar-category-list {
    display: flex;
    flex-direction: column;
}

.sidebar-category-title {
    display: flex;
    flex-direction: column;
    padding-right: 30px;
    box-sizing: border-box;
    margin-bottom: 65px;
}

.sidebar-category-title__top-line {
    background: #dcdcdc;
    height: 3px;
    width: 50%;
    align-self: flex-end;
}

.sidebar-category-title__name {
    font-size: 24px;
    font-weight: 700;
    text-transform: uppercase;
    margin-top: 25px;
    margin-bottom: 15px;
}

.sidebar-category-title__bottom-line {
    background: #ff7200;
    height: 3px;
    width: 65%;
}

.sidebar-category-list__item {
    font-size: 20px;
    text-transform: uppercase;
    color: #292929;
    margin-bottom: 25px;
}

.sidebar-category-list__item:hover {
    color: #ff7200;
}

.sidebar-category-list__item_active {
    color: #ff7200;
    font-weight: 700;
}

.detail-product__title {
    font-size: 36px;
    text-transform: uppercase;
    color: #292929;
    margin-top: 69px;
    padding-left: 33px;
    padding-right: 33px;
}

.detail-product__vendor-code {
    font-size: 20px;
    color: #aeadad;
    font-weight: 300;
    padding-left: 33px;
    margin-bottom: 21px;
    display: block;
}

.detail-product__availabel {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding-right: 15px;
    box-sizing: border-box;
    font-size: 20px;
    font-weight: 300;
    margin-bottom: 25px;
}

.detail-product__availabel > span {
    margin-right: 10px;
    border-bottom: 1px solid #292929;
}

.detail-product__availabel > span:hover {
    color: #ff7200;
    border-bottom: 1px solid #ff7200;
    cursor: pointer;
}

.detail-product-head {
    display: flex;
}

.detail-product-head-photo {
    width: 50%;
    box-sizing: border-box;
    padding-left: 40px;
    padding-right: 40px;
    position: relative;
}

.js-detail-product-bottom-slider {
    box-sizing: border-box;
    padding-left: 25px;
    padding-right: 25px;
    margin-top: 40px;
}

.detail-product-bottom-slider__slide {
    box-sizing: border-box;
    padding-left: 7px;
    padding-right: 7px;
    cursor: pointer;
}

.js-detail-product-bottom-slider .slick-prev:before {
    color: #dcdcdc;
    content: "\2039";
    font-size: 40px;
    font-weight: 800;
}

.js-detail-product-bottom-slider .slick-prev:hover:before,
.js-detail-product-bottom-slider .slick-next:hover:before {
    color: #ff7200;
}

.js-detail-product-bottom-slider .slick-next:before {
    color: #dcdcdc;
    content: "\203a";
    font-size: 40px;
    font-weight: 800;
}

.js-detail-product-bottom-slider .slick-next {
    right: 0px;
    height: 55px;
}

.js-detail-product-bottom-slider .slick-prev {
    left: 0;
    height: 55px;
}

.detail-product-form__input {
    border: 1px solid #dcdcdc;
    border-radius: 0;
    box-sizing: border-box;

    font-weight: 300;
    font-size: 24px;
    color: #292929;

    padding: 5px 0px 5px 15px;;

    -webkit-appearance: none;
    -moz-appearance: none;
    text-indent: 0.01px;
    text-overflow: '';
    -ms-appearance: none;
    appearance: none !important;
}

.detail-product-form__input_select {
    /* clean-css ignore:start */
    background: url(../img/images/detail_product/chevron-down.png) no-repeat 95% 50% #FFFFFF;
    /* clean-css ignore:end */
    width: 100%;
}

.detail-product-options {
    width: 50%;
    padding-left: 40px;
    padding-right: 65px;
    box-sizing: border-box;
}

.detail-product-form {
    width: 100%;
    margin-top: -15px;
    margin-bottom: 15px;
}

.detail-product-form__label {
    display: block;

    color: #747373;
    font-weight: 300;
    font-size: 20px;

    padding: 15px 5px 2px 15px;
}

.detail-product-form__radio-input {
    display: none;
}

.detail-product-form__radio {
    display: inline-block;

    width: 32px;
    height: 32px;

    margin-right: 10px;
    margin-bottom: 10px;
    padding: 0;

    border: 3px solid transparent;
    border-radius: 7px;
    background-position: 50% 50%;
    background-repeat: no-repeat;
    background-size: contain;
    cursor: pointer;
}

.detail-product-form__radio-input:checked + .detail-product-form__radio {
    border: 3px solid #ff7200;
}

.detail-product-form__radio_2x-size {
    width: 45px;
    height: 45px;
    border-radius: 10px;
    border-width: 2px !important;
}

.detail-product-form__count {
    color: #dcdcdc;
    font-size: 20px;
}

.detail-product-form__label_count {
    padding-top: 0;
}

.detail-product-form__input_count {
    width: 95px;
    text-align: center;
    padding-left: 0;
}

.detail-product-group_row {
    display: flex;
    align-items: center;
}

.detail-product-form__count:hover {
    color: #ff7200;
    cursor: pointer;
}

.detail-product-price {
    display: flex;
    align-items: flex-end;
    margin-top: 10px;
}

.detail-product-price.detail-product-price-block {
    display: block;
}

.detail-product-amount-wrapper {
    margin-top: 10px;
}

.detail-product-price__title {
    color: #747373;
    font-size: 20px;
    font-weight: 300;

    padding: 15px 5px 2px 15px;
}

.detail-product-price__standart {
    font-weight: 700;
    font-size: 30px;
    color: #ff7200;
}

.detail-product-price__sale {
    font-size: 20px;
    color: #747373;
    font-weight: 300;
    text-decoration: line-through;
}

.detail-product-price_sale-active {
    justify-content: space-between;
}

.detail-product-price_sale-active > .detail-product-price__standart {
    color: #fc3636;
}

.detail-product-price > .detail-product-price__sale {
    display: none;
}

.detail-product-price_sale-active > .detail-product-price__sale {
    display: inline;
}

.detail-product-sale-timer {
    display: none;
    color: #fc3636;
    text-align: center;
    font-size: 18px;
    margin-top: 10px;
    font-weight: 300;
}

.detail-product-sale-timer-active {
    display: block;
}

.detail-product-buy-btn {
    background: rgb(255, 114, 0);
    color: #ffffff;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 17px 0 13px;
    font-weight: 300;
    font-size: 24px;
    text-transform: uppercase;
    border-radius: 7px;
    margin-top: 10px;
    width: 100%;
    box-sizing: border-box;
    cursor: pointer;
}

.detail-product-buy-btn:hover {
    background: rgb(255, 135, 39);
}

.detail-product-calc-btn-block {
    padding-left: 15px;
    padding-right: 15px;
    margin-top: 30px;
}

.detail-product-calc-btn__text {
    text-align: center;
    display: block;
    font-weight: 300;
    font-size: 20px;
    color: #292929;
}

.detail-product-description-wrapper {
    padding-left: 15px;
    padding-right: 15px;
    margin-top: 40px;
    margin-bottom: 40px;
}

.js-detail-product-description__tab {
    cursor: pointer;
    padding: 13px 30px;
    border-left: 2px solid transparent;
    border-top: 2px solid transparent;
    border-right: 2px solid transparent;
    border-radius: 5px 5px 0 0;
    font-weight: 300;
    font-size: 20px;
    color: #747373;
}

.js-tab-page {
    overflow: hidden;
    display: none;
    opacity: 0;
    padding: 25px;
    position: relative;
}

.detail-product-description__body {
    background: #ffffff;
    border: 2px solid rgb(241, 239, 239);
    border-radius: 3px;
}

.js-detail-product-description__tab.active {
    border-left: 2px solid rgb(241, 239, 239);
    border-top: 2px solid rgb(241, 239, 239);
    border-right: 2px solid rgb(241, 239, 239);
    border-radius: 5px 5px 0 0;
    background-color: #ffffff;
}

.js-tab-page.active {
    animation: js-tab-page ease-in-out 0.5s;
    display: block;
    opacity: 1;
}

.js-detail-product-description__tabs {
    display: flex;
    justify-content: center;
    position: relative;
    bottom: -2px;
}

@-webkit-keyframes js-tab-page {
    0% {
        display: none;
        opacity: 0;
    }
    1% {
        display: block;
        opacity: 0;
    }

    100% {
        display: block;
        opacity: 1;
    }
}

@-moz-keyframes js-tab-page {
    0% {
        display: none;
        opacity: 0;
    }
    1% {
        display: block;
        opacity: 0;
    }

    100% {
        display: block;
        opacity: 1;
    }
}

@-o-keyframes js-tab-page {
    0% {
        display: none;
        opacity: 0;
    }
    1% {
        display: block;
        opacity: 0;
    }

    100% {
        display: block;
        opacity: 1;
    }
}

@keyframes js-tab-page {
    0% {
        display: none;
        opacity: 0;
    }
    1% {
        display: block;
        opacity: 0;
    }

    100% {
        display: block;
        opacity: 1;
    }
}

.detail-product-description-table__td {
    padding: 10px 15px;
    font-weight: 300;
    font-size: 20px;
    color: #292929;
}

.detail-product-description-table {
    width: 75%;
    margin: 20px auto;
    border-collapse: collapse;
}

.detail-product-description-table__tr:nth-of-type(2n+1) {
    background: #ffead9;
}

.detail-product-description-table__td:nth-of-type(2n) {
    text-align: right;
}

.detail-product-description-body__text {
    font-weight: 300;
    font-size: 20px;
    color: #292929;
    padding: 50px;
    line-height: 1.1;
}

.dp-review__img {
    display: block;
    width: 65px;
    height: 65px;
    object-fit: cover;
    border: 2px solid #dcdcdc;
    border-radius: 50%;
    margin-left: 15px;
    margin-right: 15px;
}

.dp-review {
    display: flex;
    justify-content: space-between;
    border-top: 2px solid #dcdcdc;
    padding-top: 20px;
    margin-bottom: 20px;
}

.dp-review__date-block {
    color: #aeadad;
    font-weight: 300;
    font-size: 14px;
    text-align: right;
}

.dp-review-number {
    margin-bottom: 15px;
    display: block;
}

.dp-review__name {
    display: block;
    text-transform: uppercase;
    font-size: 14px;
    color: #ff7200;
    margin-bottom: 7px;
}

.dp-review-main-block {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
}

.dp-review__city {
    display: block;
    margin-bottom: 7px;
    font-size: 14px;
    font-weight: 300;
    color: #292929;
}

.review-name__stars__dp-modif {
    margin-bottom: 7px;
}

.review-name__stars__dp-modif > .fa-star {
    font-size: 16px;
}

.dp-review__more-btn {
    display: none;
    color: #ff7200;
    font-size: 14px;
    font-weight: 300;
    align-self: flex-end;
    padding-top: 5px;
    padding-bottom: 5px;
    cursor: pointer;
}

.dp-review__more-btn-text {
    cursor: pointer;
}

.dp-review__more_display {
    display: inline-block;
}

.dp-review-text-block {
    position: relative;
}

.dp-review-text__gradient {
    position: absolute;
    opacity: 1;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    box-shadow: inset 0px -77px 50px -50px #ffffff;
}

.dp-review-text__gradient_none {
    opacity: 0;
}

.dp-review__text {
    line-height: 1.4;
    font-weight: 300;
    font-size: 14px;
    max-height: 57px;
    overflow: hidden;
}

.dp-reviews {
    position: relative;
}

.dp-review__text_autoheight {
    max-height: 1000px;
}

.dp-review__more-btn-icon_rotate {
    transform: rotate(180deg);
}

.dp-review-add-block {
    display: flex;
    width: 100%;
}

.dp-review-btn_add {
    margin: 25px 15px 0;
}

.dp-more-products {
    font-weight: 300;
    font-size: 24px;
    text-transform: uppercase;
    text-align: center;
    margin-top: 43px;
    margin-bottom: 30px;
    color: #292929;
}

.detail-product-ajax-preloader {
    display: none;
    width: 30px;
    margin: 0 auto;
}

.detail-product-price_ajax .detail-product-ajax-preloader {
    display: block;
}

.detail-product-price_ajax .detail-product-price__standart,
.detail-product-price_ajax .detail-product-price__sale {
    display: none;
}

.detail-product-description-body_ajax__preload-container {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    display: none;
    justify-content: center;
    align-items: center;
    background-color: rgba(255, 255, 255, .7);
}

.detail-product-description-body_ajax__preload-img {
    width: 65px;
}

.detail-product-description-body_ajax .detail-product-description-body_ajax__preload-container {
    display: flex;
}

.detail-product-specs {
    font-weight: 300;
    font-size: 18px;
    color: #292929;
    display: none;
}

.detail-product-form_calc-active .detail-product-form,
.detail-product-form_calc-active .detail-product-buy-btn,
.detail-product-form_calc-active .detail-product-price{
    display: none;
}

.detail-product-form_calc-active .detail-product-specs {
    display: block;
}

.detail-product-specs__list {
    list-style: none;
    padding: 0;
    margin-left: 10px;
    margin-bottom: 45px;
}

.detail-product-specs__title {
    display: block;
    margin-top: 25px;
    margin-bottom: 15px;
}

.detail-product-specs__item {
    position: relative;
    padding-left: 20px;
    margin-bottom: 4px;
}

.detail-product-specs__item:before {
    content: '';
    position: absolute;
    top: 50%;
    left: 0;
    width: 9px;
    height: 9px;
    margin-top: -4px;
    border-radius: 50%;
    background: #dcdcdc;
}

.sidebar-category-sticky {
    position: sticky;
    top: 58px;
    padding-left: 15px;
}

.detail-product-description-body__table_none {
    display: none;
}

.js-dp-tab__table_none {
    display: none;
}

.dp-form-calc-title {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 30px;
    margin-bottom: 25px;
}

.dp-form-calc-title__line {
    width: 100%;
    height: 2px;
    background-color: #ff7200;
    margin-left: 40px;
    margin-right: 40px;
}

.dp-form-calc-title__name {
    text-transform: uppercase;
    white-space: nowrap;
    font-weight: 300;
    font-size: 24px;
    color: #292929;
    text-align: center;
}

.dp-calc-block {
    background: #ffffff;
    border: 2px solid rgb(241, 239, 239);
    border-radius: 7px;
    margin-left: 40px;
    margin-right: 40px;
    min-height: 300px;
}

.dp-calc-legend__star {
    display: block;
    color: #ff7200;
    font-family: sans-serif;
    font-size: 45px;
    width: 20px;
    height: 20px;
    text-align: center;
}

.dp-calc-legend__radio {
    display: block;
    width: 20px;
    height: 20px;
    background: #dcdcdc;
    border-radius: 50%;
}

.dp-calc-legend__checkbox {
    display: block;
    width: 20px;
    height: 20px;
    background: #dcdcdc;
    border-radius: 4px;
}

.dp-calc-legend__string {
    margin-bottom: 7px;
    display: flex;
    align-items: center;
}

.dp-calc-legend__note {
    display: block;
    margin-bottom: 5px;
}

.dp-calc-legend {
    margin-top: 20px;
    margin-left: 75px;
    margin-right: 75px;
    font-weight: 300;
    font-size: 18px;
    color: #aeadad;
}

.dp-form-calc {
    max-height: 0px;
    overflow: hidden;
}

.dp-form-calc_active {
    max-height: 1500px;
}

@media screen and (max-width: 1169px) {
    .detail-product-options {
        padding-left: 0;
        padding-right: 40px;
    }
}

@media screen and (max-width: 959px) {
    .sidebar-category {
        display: none;
    }

    .detail-product {
        width: 768px;
    }
}

@media screen and (max-width: 767px) {
    .detail-product {
        width: 100%;
    }

    .detail-product__availabel > span {
        border: none;
    }

    .detail-product__availabel > span:hover {
        border: none;
    }
}

@media screen and (max-width: 640px) {
    .detail-product__title {
        font-size: 22px;
        padding-left: 15px;
        padding-right: 15px;
        margin-top: 38px;
    }

    .detail-product__vendor-code {
        font-size: 16px;
        padding-left: 15px;
    }

    .detail-product__availabel > span {
        font-size: 14px;
    }

    .detail-product__availabel_img {
        width: 25px;
        height: 25px;
    }

    .detail-product-head {
        flex-direction: column;
    }

    .detail-product-head-photo {
        width: 100%;
    }

    .detail-product-options {
        width: 100%;
        padding-left: 40px;
        margin-top: 40px;
    }

    .detail-product-form__label {
        font-size: 18px;
    }

    .detail-product-price__title {
        font-size: 18px;
    }

    .detail-product-form__input {
        font-size: 18px;
    }

    .detail-product-price__standart {
        font-size: 24px;
    }

    .detail-product-price__sale {
        font-size: 16px;
    }

    .detail-product-sale-timer {
        font-size: 16px;
    }

    .detail-product-buy-btn {
        font-size: 18px;
        padding-top: 15px;
        padding-bottom: 15px;
    }

    .detail-product-specs {
        font-size: 16px;
    }

    .detail-product-calc-btn__text {
        font-size: 16px;
    }

    .dp-form-calc-title__name {
        font-size: 18px;
    }

    .dp-calc-legend {
        font-size: 16px;
    }

    .dp-calc-legend__radio,
    .dp-calc-legend__star,
    .dp-calc-legend__checkbox {
        width: 16px;
        height: 16px;
    }

    .js-detail-product-description__tab {
        font-size: 16px;
    }

    .detail-product-description-body__text {
        font-size: 16px;
    }

    .detail-product-description-table__td {
        font-size: 16px;
    }

    .detail-product__availabel > span {
        padding-left: 15px;
        text-align: right;
    }

    .detail-product-form__count {
        font-size: 16px;
        padding-left: 5px;
        padding-right: 5px;
    }

    .detail-product-form__input_count {
        width: 75px;
    }

    .js-detail-product-description__tab {
        padding: 10px 10px;
    }

    .js-detail-product-description__tabs {
        justify-content: space-between;
    }

    .detail-product-description-body__text {
        padding: 15px;
    }

    .js-tab-page {
        padding: 15px;
    }

    .detail-product-description-table {
        margin: 0;
        width: 100%;
    }

    .dp-review__img,
    .dp-review__date-block {
        display: none;
    }

    .dp-review-add-block {
        flex-direction: column;
    }

    .dp-desc_mob-flex {
        justify-content: flex-start;
    }

    .dp-form-calc-title__line {
        margin-left: 15px;
        margin-right: 15px;
    }

    .dp-calc-block {
        margin-left: 15px;
        margin-right: 15px;
    }

    .dp-calc-legend {
        margin-left: 15px;
        margin-right: 15px;
    }

    .dp-more-products {
        margin-top: 20px;
        margin-bottom: 10px;
        font-size: 20px;
    }

    .dp-review-btn_add {
        margin-top: 15px;
        margin-left: 0;
        margin-right: 0;
    }

    .detail-product-description-wrapper {
        margin-top: 20px;
        margin-bottom: 20px;
    }

    .detail-product-description-body_ajax__preload-img {
        width: 45px;
    }
}

@media screen and (max-width: 414px) {
    .detail-product__title {
        font-size: 18px;
        padding-left: 15px;
        padding-right: 15px;
        margin-top: 38px;
    }

    .detail-product__vendor-code {
        font-size: 12px;
    }

    .detail-product__availabel > span {
        font-size: 12px;
    }

    .detail-product__availabel_img {
        width: 25px;
        height: 25px;
    }

    .detail-product-head {
        flex-direction: column;
    }

    .detail-product-head-photo {
        width: 100%;
    }

    .detail-product-options {
        width: 100%;
        padding-left: 40px;
        margin-top: 40px;
    }

    .detail-product-form__label {
        font-size: 16px;
    }

    .detail-product-price__title {
        font-size: 16px;
    }

    .detail-product-form__input {
        font-size: 16px;
    }

    .detail-product-price__standart {
        font-size: 24px;
    }

    .detail-product-price__sale {
        font-size: 14px;
    }

    .detail-product-sale-timer {
        font-size: 14px;
    }

    .detail-product-buy-btn {
        font-size: 14px;
        padding-top: 10px;
        padding-bottom: 10px;
    }

    .detail-product-specs {
        font-size: 14px;
    }

    .detail-product-calc-btn__text {
        font-size: 14px;
    }

    .dp-form-calc-title__name {
        font-size: 16px;
    }

    .dp-calc-legend {
        font-size: 14px;
    }

    .dp-calc-legend__radio,
    .dp-calc-legend__star,
    .dp-calc-legend__checkbox {
        width: 16px;
        height: 16px;
    }

    .js-detail-product-description__tab {
        font-size: 14px;
    }

    .detail-product-description-body__text {
        font-size: 14px;
    }

    .detail-product-description-table__td {
        font-size: 14px;
    }

    .detail-product__availabel > span {
        border: none;
        padding-left: 15px;
        text-align: right;
    }

    .detail-product-form__count {
        font-size: 16px;
        padding-left: 5px;
        padding-right: 5px;
    }

    .detail-product-form__input_count {
        width: 75px;
    }

    .js-detail-product-description__tab {
        padding: 10px 5px;
    }

    .js-detail-product-description__tabs {
        justify-content: space-between;
    }

    .detail-product-description-body__text {
        padding: 15px;
    }

    .js-tab-page {
        padding: 15px;
    }

    .detail-product-description-table {
        margin: 0;
        width: 100%;
    }

    .dp-review__img,
    .dp-review__date-block {
        display: none;
    }

    .dp-review-add-block {
        flex-direction: column;
    }

    .dp-desc_mob-flex {
        justify-content: flex-start;
    }

    .dp-form-calc-title__line {
        margin-left: 15px;
        margin-right: 15px;
    }

    .dp-calc-block {
        margin-left: 15px;
        margin-right: 15px;
    }

    .dp-calc-legend {
        margin-left: 15px;
        margin-right: 15px;
    }

    .dp-more-products {
        margin-top: 20px;
        margin-bottom: 10px;
        font-size: 16px;
    }

    .dp-review-btn_add {
        margin-top: 15px;
        margin-left: 0;
        margin-right: 0;
    }

    .detail-product-description-wrapper {
        margin-top: 20px;
        margin-bottom: 20px;
    }
}

.dp-top-slider__zoom-link {
    display: block;
}

.detail-product-top-slider__slide,
.detail-product-bottom-slider__slide {
    display: block;
    width: 100%;
}

.dp-zoom-icon {
    font-size: 40px;
    color: #ff7200;
    position: absolute;
    margin-left: 10px;
    margin-top: 10px;
    z-index: 99;
    top: 1px;
    opacity: 0;
    cursor: pointer;
}

.js-detail-product-top-slider:hover ~ .dp-zoom-icon {
    opacity: 1;
}

.dp-zoom-icon:hover {
    opacity: 1;
}

.detail-product-buy-btn_success {
    background-color: rgb(30, 195, 12) !important;
}

.detail-product-buy-btn_fail {
    background-color: rgb(204, 32, 32) !important;
}

.dp-review-empty {
    font-size: 18px;
    text-align: center;
    margin-top: 50px;
    margin-bottom: 50px;
}

/*---- Конструктор описаний ----- */

.detail-product__paragraph {
    margin-bottom: 15px;
}

.detail-product__paragraph a {
    color: #ff7200;
}

.detail_product__paragraph-icon {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
}

.detail_product__paragraph-icon i {
    font-size: 60px;
    margin-right: 15px;
}

.detail_product__paragraph-icon i.fa::before {
    width: 60px;
    display: block;
    text-align: center;
}

.detail_product__paragraph-img {
    display: flex;
    align-items: flex-start;
    margin-bottom: 15px;
}

.detail_product__paragraph-img img {
    display: block;
    width: 30%;
}

.detail_product__paragraph-img img:first-child {
    margin-right: 15px;
}

.detail_product__paragraph-img p:first-child {
    margin-right: 15px;
}

.detail-product__about-h1 {
    display: block;
    font-weight: 700;
    font-size: 22px;
    margin-bottom: 15px;
}

.detail-product__about-h2 {
    display: block;
    font-weight: 700;
    font-size: 18px;
    margin-bottom: 15px;
}

.detail-product__about-h1.left,
.detail-product__about-h2.left,
.detail-product__paragraph.left {
    text-align: left;
}

.detail-product__about-h1.center,
.detail-product__about-h2.center,
.detail-product__paragraph.center {
    text-align: center;
}

.detail-product__about-h1.right,
.detail-product__about-h2.right,
.detail-product__paragraph.right {
    text-align: right;
}

.detail-product__horizontal-list {
    list-style: none;
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 25px;
    justify-content: center;
}

.detail-product__horizontal-list li {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding: 0 15px;
    box-sizing: border-box;
    width: 25%;
}

.detail-product__horizontal-list li i {
    font-size: 60px;
    margin-bottom: 5px;
}

.detail-product__horizontal-list li a {
    display: block;
    color: #ff7200;
}

.detail-product__notes {
    color: #5e5d5c;
    font-size: 16px;
    font-style: italic;
    margin-left: 10px;
    margin-bottom: 15px;
}

.detail-product__list {
    margin-bottom: 15px;
}

.detail-product__compare {
    display: flex;
}

.detail-product__compare-list {
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.detail-product__list li {
    margin-bottom: 5px;
}

.detail_product__table {
    width: 100%;
    border-spacing: 0;
    margin-bottom: 15px;
}

.detail_product__table > tbody:first-child > tr:nth-of-type(2n - 1) {
    background: #ffead9;
}

.detail_product__table > thead:first-child + tbody > tr:nth-of-type(2n) {
    background: #ffead9;
}

.detail_product__table > thead > tr {
    background: #ff7200;
    color: white;
    font-weight: 700;
}

.detail_product__table td, .detail_product__table th {
    padding: 7px 10px;
}

.youtube-video-frame {
    position: relative;
    padding-bottom: 56.25%;
    padding-top: 25px;
    height: 0;
    margin-top: 30px;
    margin-bottom: 30px;
}

.youtube-video-frame iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

@media screen and (max-width: 1169px) {
    .detail-product__horizontal-list li i {
        font-size: 45px;
    }
}

@media screen and (max-width: 650px) {
    .detail-product__horizontal-list li {
        width: 50%;
        margin-bottom: 15px;
    }
}

.detail-product__title {
    margin-bottom: 5px;
}

.detail-product__prod-absence {
    margin-top: 20px;
    color: #4a4a4a;
    display: none;
}

.bt-product__img {
    max-width: 160px;
}