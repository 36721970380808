.mainIndividual {
    text-align: center;
    font-weight: 300;
    font-size: 24px;
}

.mainIndividual > span {
    display: block;
    margin-top: 20px;
    margin-bottom: 45px;
}

.mainIndividual > img {
    width: 100%;
}

@media screen and (max-width: 767px) {
    .mainIndividual {
        display: none;
    }
}