.feedbackForm {
    width: 80%;
    box-sizing: border-box;
    background: rgb(116, 115, 115);
    border: 4px solid rgb(255, 114, 0);
    border-radius: 10px;
    margin-left: 15px;
    padding-top: 45px;
    padding-bottom: 15px;
}

.feedbackFormBlock__title {
    display: block;
    font-weight: 400;
    color: rgb(255, 114, 0);
    text-align: center;
    margin-bottom: 20px;
    font-size: 20px;
    text-transform: uppercase;
}

.feedbackFormBlock > form {
    text-align: center;
}

.feedbackFormBlock > form > input,
.feedbackFormBlock > form > textarea {
    width: 80%;
    box-sizing: border-box;
    font-weight: 300;
    font-size: 18px;
    background-color: rgb(136, 135, 135);
    color: #ffffff;
    padding: 15px;
    border: none;
    margin-bottom: 25px;
}

.feedbackFormBlock .feedbackForm_btn {
    background-color: rgb(255, 114, 0);
    border-radius: 10px;
    font-size: 16px;
    font-weight: 400;
    text-transform: uppercase;
}

.registration-form__errors {
    color: #e01919;
    margin-bottom: 30px;
}

.feedbackFormBlock .feedbackForm_btn:hover {
    cursor: pointer;
    background-color: rgb(255, 135, 39)
}

.feedbackFormBlock form > input::-webkit-input-placeholder {
    color: rgb(220, 220, 220)
}

.feedbackFormBlock form > input::-moz-placeholder {
    color: rgb(220, 220, 220)
}

.feedbackFormBlock form > input:-moz-placeholder {
    color: rgb(220, 220, 220)
}

.feedbackFormBlock form > input:-ms-input-placeholder {
    color: rgb(220, 220, 220)
}

.feedbackFormBlock form > textarea::-webkit-input-placeholder {
    color: rgb(220, 220, 220)
}

.feedbackFormBlock form > textarea::-moz-placeholder {
    color: rgb(220, 220, 220)
}

.feedbackFormBlock form > textarea:-moz-placeholder {
    color: rgb(220, 220, 220)
}

.feedbackFormBlock form > textarea:-ms-input-placeholder {
    color: rgb(220, 220, 220)
}

.feedbackForm__freeCall {
    display: block;
    width: 100%;
    color: #ffffff;
    font-weight: 300;
    font-size: 14px;
}

.feedbackForm__enter {
    visibility: hidden;
    font-weight: 400;
    font-size: 18px;
    color: #ff0000;
}

.feedbackForm__enter_error {
    visibility: visible;
}

.feedbackFormBlock__title_modal {
    font-size: 25px;
    margin-top: 30px;
    margin-bottom: 35px;
}

.feedbackFormBlock_modal form input,
.feedbackFormBlock_modal form textarea {
    background-color: #383838;
    width: 100%;
}

.feedbackForm_btn_success {
    background-color: rgb(30, 195, 12) !important;
}

.feedbackForm_btn_fail {
    background-color: rgb(204, 32, 32) !important;
}

.mainFeedbackBtn {
    display: none;
    padding: 0 15px;
    box-sizing: border-box;
    width: 10%;
}

.mainFeedbackBtn > div {
    display: flex;
    justify-content: center;
    align-items: center;
    background: linear-gradient(rgba(255, 255, 255, .1) -150%, rgb(255, 114, 0) 50%, rgba(0, 0, 0, .5) 300%);
    box-shadow: 0px 10px 53px -8px rgba(122, 122, 122, 0.71);
    height: 100%;
    width: 100%;
    color: #ffffff;
    border-radius: 5px;
}

@media screen and (max-width: 959px) {
    .mainFeedbackBtn {
        display: flex;
    }
    .mainFeedbackBtn > div:hover {
        background: linear-gradient(rgba(255, 255, 255, .1) -110%, rgb(255, 114, 0) 50%, rgba(0, 0, 0, .7) 300%);
    }
}

@media screen and (min-width: 661px) and (max-width: 959px) {
    .mainFeedbackBtn > div > span {
        transform: rotate(270deg);
        white-space: nowrap;
    }
}

@media screen and (max-width: 660px) {

    .mainFeedbackBtn {
        width: 100%;
        height: 60px;
        margin-bottom: 35px;
    }
}

@media screen and (max-width: 400px) {

    .feedbackFormBlock__title_modal {
        font-size: 18px;
    }
}

.review-form-btn__star-radio {
    display: none;
}

.review-form-btn__star-radio-label {
    color: #dcdcdc;
    font-size: 20px;
    padding-left: 2px;
    padding-right: 2px;
}

.reviewStars {
    display: flex;
    flex-direction: row-reverse;
    justify-content: flex-end;
    align-items: center;
    padding: 15px;
    margin-bottom: 25px;
    background-color: #383838;
}

.review-form-btn__star-radio:checked ~ label {
    color: rgb(255, 114, 0);
}

.review-form-btn__star-radio:hover ~ label {
    color: rgb(255, 114, 0);
}

.review-form-btn__star-radio-label:hover {
    cursor: pointer;
}

.review-form-btn__star_title {
    font-weight: 300;
    color: rgb(220, 220, 220);
    font-size: 18px;
    margin-right: 10px;
}

#formAjaxReview__file-send {
    display: none;
}

.formAjaxReview__file-block {
    padding: 15px;
    display: flex;
    justify-content: space-between;
    background-color: #383838;
    font-weight: 300;
    margin-bottom: 25px;
    color: rgb(220, 220, 220);
}

.formAjaxReview__file-block label:hover {
    cursor: pointer;
    color: rgb(255, 114, 0);
}

.formAjaxReview__captha-img {
    margin-bottom: 25px;
}

.feedbackForm__freeCall_ok {
    font-size: 16px;
    font-weight: 300;
    display: block;
    width: 100%;
    color: #fff;
}




