:root {
    --orange-lvl-0: #ff8727;
    --orange-lvl-1: #ff7200;
}

body {
    background: #ffffff;
    line-height: 1;
    color: black;
    background-size: cover;
    background-repeat: no-repeat;
    width: 100%;
    height: 100%;
    font-family: "Circe", sans-serif;
    font-weight: 400;
}

header {
    position: sticky;
    top: 0;
    z-index: 998;
    width: 100%;
}

/* Анимация */

.topBar > ul > li > a,
.topBar > ul > li > ul > li > a,
.topMenu > ul > li > ul > li > a,
.topMenu > ul > li > a > i,
.topMenu > ul > li > a,
.topMenu > ul > li:first-child > a,
.linkCart,
.fourProductItems_block,
.fourProductItems_calcRound-popup,
.fourProductItems_calcRound,
.fourProductItems_shadow,
.feedbackForm .feedbackForm_btn,
.mainOurWorks__line1,
.mainOurWorks__line2,
.mainOurWorks__line3,
.mainOurWorks__line4,
.mainOurWorks__gradient,
.mainOurWorks__more,
.mainNewsBlock__textWrapper,
.mainNewsBlock__btn,
.footerBlockRight__vk,
.footerBlockRight__facebook,
.footerBlockRight__instagram,
.footerBlockRight__skype,
.footerBlockRight__youtube,
.footerBlockRight__email,
.footerBlockCenter__menu > ul > li > a,
.footerBlockCenter__category > ul > li > a,
.footerBlockLeft__phone,
.mainFeedbackBtn > div,
.mainContacts__text,
.news-block__img,
.news-text__link,
.pagination__page,
.review-block__more-btn-icon,
.review-block__review-text,
.review-block__review-text_gradient,
.sidebar-category-list__item,
.detail-product__availabel > span,
.detail-product-form__count,
.detail-product-buy-btn,
.js-tab-page,
.dp-review__more-btn-icon,
.dp-review-text__gradient,
.dp-review__text,
.review-form-btn__star-radio-label,
.dp-zoom-icon,
.bt-product-options table,
.bt-product__delete .fa-times,
.bt-product-options__tr,
.bt-reduce__icon,
.bt-product__name-link,
.bt-counter__minus,
.bt-counter__plus,
.bt-reduce,
.bt-buy-btn,
.bt-order-promo__submit,
.page-empty-basket__link-catalog,
.purchase-tab__head,
.purchase-tab__head-icon,
.purchase-contacts__next-btn,
.purchase-contacts__input,
.purchase-contacts__nav-btn,
.purchase-info__file-label,
.purchase-delivery__delivery-label,
.purchase-delivery__delivery-label > span,
.purchase-pay__method,
.personal-order__item-link,
.personal-order__head,
.personal-order__head i,
.personal-profile-form__input,
.personal-btn__link,
.search-popup,
.search-popup__icon,
.search-popup__submit,
.search-popup-wrapper,
.new-password__input,
.new-password__submit,
.broad-btn,
.slider-company__arrow,
.calc-control__btn,
.calc-step__input-text,
.search-item,
.search-item__link,
.calc-add-done__link {
    -webkit-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease;
}

.fourProductItems_textBlock > p {
    -webkit-transition: all 1s linear;
    -moz-transition: all 1s linear;
    -o-transition: all 1s linear;
    transition: all 1s linear;
}

.fourProductItems_calcRound-popup,
.topBar > ul > li > ul,
.topMenu > ul > li > ul {
    -webkit-transition: all 0.1s linear;
    -moz-transition: all 0.1s linear;
    -o-transition: all 0.1s linear;
    transition: all 0.1s linear;
}

.dp-form-calc {
    -webkit-transition: all .7s ease-in-out;
    -moz-transition: all .7s ease-in-out;
    -o-transition: all .7s ease-in-out;
    transition: all .7s ease-in-out;
}

.wrapBg {
    box-sizing: border-box;
    background-color: rgb(250, 250, 250);
    border-left: 2px solid rgb(241, 239, 239);
    border-right: 2px solid rgb(241, 239, 239);
}

@media screen and (max-width: 768px) {
    .wrapBg {
        border: none;
    }
}

.wideContainer {
    width: 100%;
}

@media screen and (min-width: 1170px) {
    .wrapper {
        width: 1170px;
        margin: 0 auto;
    }
}

@media screen and (max-width: 1169px) {
    .wrapper {
        width: 960px;
        margin: 0 auto;
    }
}

@media screen and (max-width: 959px) {
    .wrapper {
        width: 768px;
        margin: 0 auto;
    }

    .topMenu > ul > li > ul {
        top: 98px;
    }
}

@media screen and (max-width: 767px) {
    .wrapper {
        width: 100%;
        margin: 0 auto;
    }
}

@media screen and (max-width: 767px) {
    .vis-xs {
        display: block !important;
    }
}

@media screen and (min-width: 768px) {
    .vis-xs {
        display: none !important;
    }
}

@media screen and (min-width: 768px) and (max-width: 960px) {
    .vis-sm {
        display: block !important;
    }
}

@media screen and (max-width: 767px) {
    .vis-sm {
        display: none !important;
    }
}

@media screen and (min-width: 960px) {
    .vis-sm {
        display: none !important;
    }
}

@media screen and (min-width: 960px) and (max-width: 1169px) {
    .vis-md {
        display: block !important;
    }
}

@media screen and (max-width: 959px) {
    .vis-md {
        display: none !important;
    }
}

@media screen and (min-width: 1170px) {
    .vis-md {
        display: none !important;
    }
}

@media screen and (min-width: 1170px) {
    .vis-lg {
        display: block !important;
    }
}

@media screen and (max-width: 1169px) {
    .vis-lg {
        display: none !important;
    }
}

@media screen and (max-width: 767px) {
    .hid-xs {
        display: none !important;
    }
}

@media screen and (min-width: 768px) and (max-width: 959px) {
    .hid-sm {
        display: none !important;
    }
}

@media screen and (min-width: 960px) and (max-width: 1169px) {
    .hid-md {
        display: none !important;
    }
}

@media screen and (min-width: 1170px) {
    .hid-lg {
        display: none !important;
    }
}



/* Хэлперы */

.orangeText {
    color: rgb(255, 114, 0);
}

.orangeBack {
    background-color: rgb(255, 114, 0);
}

.orangeBorder {
    border-color: rgb(255, 114, 0);
}

.pad-r {
    padding-right: 5px;
}

.pad-l {
    padding-left: 5px;
}

.pad-lr {
    padding-left: 15px;
    padding-right: 15px;
    box-sizing: border-box;
}

.g-clearfix:after {
    content: "";
    display: table;
    clear: both;
}


.page_index {
    padding-top: 3%;
}


.content {
    background-color: rgb(250, 250, 250);
    border-left: solid 2px rgb(241, 239, 239);
    border-right: solid 2px rgb(241, 239, 239);
}

.headTitle {
    width: 100%;
    height: 180px;
    box-shadow: 0px -4px 22px 7px #000000;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
}

.headTitle h1 {
    color: #ff7200;
    position: absolute;
    text-transform: uppercase;
    font-weight: 800;
    font-size: 64px;
    text-align: center;
    text-shadow: 0px 1px 32px rgba(0, 0, 0, 0.5);
}

.headTitle img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.mainPageH2 h2 {
    font-weight: 300;
    font-size: 30px;
    text-align: center;
    color: rgb(41, 41, 41);
}

.mtForTitle {
    margin-top: 40px;
}

.mtForSlider {
    margin-top: 80px;
}

.gtm-submit__form-callback .error {
    border: 1px solid red;
}


@media screen and (max-width: 400px) {

    .mainPageH2 h2 {
        font-size: 22px;
    }

}
