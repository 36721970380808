/* ---- Главные стили - Начало ----*/

.calc {
    font-size: 16px;
    padding: 3%;
}

.calc-more-info {
    display: flex;
    justify-content: center;
    margin-bottom: 15px;
}

/* Стили для заглушки реконструкции калькулятора */

.calc_reconstruction + .calc-control {
    display: none;
}

.calc-reconstruction {
    min-height: 200px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
}

.calc-reconstruction__title {
    font-size: 2em;
    color: #ff7200;
    text-align: center;
    text-transform: uppercase;
    font-weight: 600;
    margin-bottom: 1em;
}

.calc-reconstruction__img {
    width: 30%;
    margin-bottom: 2em;
}

.calc-reconstruction__text {
    font-size: 1.5em;
    color: #aeadad;
}

/* Режимы отладки */

.calc__mods {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    margin-bottom: 1em;
}

.calc-mod {
    display: flex;
    align-items: center;
    background: #ff7200;
    border-radius: 200px;
    padding: .625em .9375em;
    margin: 0 .9375em .625em 0;
}

.calc-mod__text {
    color: #ffffff;
    font-size: 1em;
    position: relative;
}

.calc-mod:before {
    content: "";
    /* clean-css ignore:start */
    background: url("../img/images/calc/svg/warning.svg") no-repeat center;
    /* clean-css ignore:end */
    width: 1.5em;
    height: 1.5em;
    margin-right: 10px;
}

.calc-error-log__error {
    border: 2px solid red;
}

.calc-step__title {
    font-weight: 300;
    font-size: 1.5em;
    color: #292929;
    text-align: center;
    text-transform: uppercase;
    width: 100%;
    display: block;
}

.calc-step__instruction {
    font-size: 1em;
    text-align: center;
    color: #aeadad;
}

.calc-step__inner {
    display: flex;
    justify-content: space-around;
    width: 100%;
}

.calc-step__content {
    width: 40%;
    margin-top: 25px;
}

.calc-step__img-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
}

.calc-step-img {
    width: 100%;
}

.calc-pre-price {
    text-align: right;
}

.calc-pre-price__title {
    font-size: 1.5em;
    color: #ff7200;
}

.calc-pre-price__text {
    font-size: 1.25em;
    font-weight: 600;
}

.calc-pre-price__unit {
    font-size: 1.25em;
}

.calc-flex-container {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    width: 100%;
}

.calc-flex-row {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-bottom: .9375em;
}

/* Стили инпутов с единицами измерения (Размеры) */
.calc-input {
    margin-bottom: .9375em;
}

.calc-input_short-width {
    width: 47%;
    margin: 0;
}

.calc-input__wrapper {
    display: flex;
    flex-wrap: wrap;
    position: relative;
}

.calc-input__label {
    font-size: 1.375em;
    color: #747373;
    font-weight: 300;
    margin-bottom: .3125em;
    order: -1;
}

.calc-input__text:required + .calc-input__label:after {
    content: "*";
    font-family: Arial, sans-serif;
    color: #ff7200;
}

.calc-input__label_full-width {
    display: block;
    width: 100%;
}

.calc-input__text {
    border: 1px solid #dcdcdc;
    border-radius: .3125em;
    font-size: 1.375em;
    padding: .4375em .4375em .4375em 1.75em;
    font-weight: 300;
    flex-grow: 1;
    transition: all .3s ease;
    width: 100%;
    box-sizing: border-box;
}

.calc-input__text:focus {
    color: #ff7200;
    background: #ffffff;
    border: 1px solid #ff7200;
    background-position: -20%;
    background-size: 1.2em;
}

.calc-input__text:not(:placeholder-shown) {
    background: #ffffff;
}

.calc-input__text:not(:placeholder-shown):invalid,
.calc-input__text:not(:placeholder-shown):valid {
    /* clean-css ignore:start */
    background: url("../img/images/purchase/cancel.svg") no-repeat #ffffff;
    /* clean-css ignore:end */
    color: #ff7200;
    border: 1px solid #ff7200;
    background-position: -50px;
    background-size: 1.2em;
}

.calc-input__text:not(:placeholder-shown):invalid {
    /* clean-css ignore:start */
    background: url("../img/images/purchase/cancel.svg") 10px no-repeat rgba(245, 0, 0, .1);
    /* clean-css ignore:end */
    color: red;
    border: 1px solid red;
    background-size: 1.2em;
}

.calc-input__text:not(:placeholder-shown):valid{
    /* clean-css ignore:start */
    background: url("../img/images/purchase/checked.svg") 10px no-repeat rgba(0, 245, 0, .1);
    /* clean-css ignore:end */
    color: #6ac358;
    background-size: 1.2em;
    border: 1px solid #6ac358;
}

.calc-input__text.calc-input__text_invalid:not(:placeholder-shown) {
    /* clean-css ignore:start */
    background: url("../img/images/purchase/cancel.svg") 10px no-repeat rgba(245, 0, 0, .1);
    /* clean-css ignore:end */
    color: red;
    border: 1px solid red;
    background-size: 1.2em;
}

.calc-input__text:focus{
    border-color: #ff7200;
}

input.calc-input__text[type="number"] {
    -moz-appearance:textfield;
}

.calc-input__text::-webkit-outer-spin-button,
.calc-input__text::-webkit-inner-spin-button {
    -webkit-appearance: none;
}

.calc-input__text_with-init {
    text-align: right;
    padding: .4375em 2em .4375em 1.75em;
}

.calc-input__unit {
    position: absolute;
    right: 0;
    bottom: 0;
    padding: calc(.4375em + 1px);
    font-weight: 300;
    font-size: 1.375em;
    pointer-events: none;
}

.calc-input__info {
    display: block;
    margin-top: .3125em;
    font-size: 1em;
    flex-basis: 100%;
    line-height: 1.2;
}

.calc-input__info_text-center {
    text-align: center;
}

.calc-input__info_error {
    color: red;
}

.calc-input__info_note {
    color: #aeadad;
}

.calc-input__info_warning {
    color: #ff7200;
}

.fieldset {
    width: 100%;
}

.fieldset_mb-1em {
    margin-bottom: 1em;
}
/* ---- Главные стили - Конец ----*/




/* ---- Прогресс бар ---- */

.calc-progress {
    display: flex;
    justify-content: space-between;
    padding: 0 3%;
}

.calc-progress__item {
    flex-grow: 1;
    height: 6px;
    background: #dcdcdc;
    margin-right: 5px;
    border-radius: 10px;
    position: relative;
    border: none;
    padding: 0;
    -webkit-appearance: none;
}

.calc-progress__item:not(:disabled):hover {
    background: var(--orange-lvl-0);
}

.calc-progress__item:hover .tooltip {
    visibility: visible;
}

.calc-progress__item_available {
    background: var(--orange-lvl-1);
    cursor: pointer;
}
.calc-progress__item_active {
    cursor: default;
}


.calc-progress__item_active:before {
    content: "";
    position: absolute;
    border: 10px solid transparent;
    border-top-color: var(--orange-lvl-1);
    bottom: 100%;
    left: 50%;
    transform: translateX(-50%);
}

.calc-progress__item:disabled .tooltip__text-title:after {
    display: block;
    content: "Заполните обязательные поля на пред. шаге";
    color: red;
    font-size: .75em;
    margin-top: .5em;
}

.calc-progress__item:not(:disabled) .tooltip__text-title:after {
    display: none;
}


/* Стили селекта */

.calc-select {
    margin-bottom: .9375em;
}

.calc-select_short-width {
    width: 45%;
}

.calc-select__wrapper {
    display: flex;
    flex-wrap: wrap;
    position: relative;
}

.calc-select__label {
    font-size: 1.375em;
    color: #747373;
    font-weight: 300;
    margin-bottom: .3125em;
    order: -1;
}

.calc-select__select:required + .calc-select__label:after {
    content: "*";
    font-family: Arial, sans-serif;
    color: #ff7200;
}

.calc-select__label_full-width {
    display: block;
    width: 100%;
}

.calc-select__select {
    border: 1px solid #dcdcdc;
    border-radius: .3125em;
    font-size: 1.375em;
    padding: .4375em 2.2em .4375em 1em;
    font-weight: 300;
    flex-grow: 1;
    transition: all .3s ease;
    width: 100%;
    background-size: 1.8em;
    cursor: pointer;
    /* clean-css ignore:start */
    background: url("../img/svg/dropdown-icon.svg") 97% no-repeat #fff;
    /* clean-css ignore:end */
}


/* Свитч селектор - стили переключателя наборов по селекту */

.set-switcher {
    margin-bottom: 2em;
}

.set-switcher__set-container {
    display: none;
}

.set-switcher__set-container.active {
    display: block;
}

/* Стили Чекбокса */

.calc-checkbox {
    margin-bottom: .9375em;
}

.calc-checkbox__checkbox {
    position: absolute;
    visibility: hidden;
}

.calc-checkbox__label {
    display: flex;
    position: relative;
    align-items: center;
    color: #747373;
    font-family: Circe, sans-serif;
    font-weight: 300;
    font-size: 1.375em;
    cursor: pointer;
    padding-left: 3.2em;
    min-height: 1.5em;
}

.calc-checkbox__label:before {
    display: block;
    position: absolute;
    left: 0;
    content: "";
    width: 2.7em;
    box-sizing: border-box;
    height: 1.5em;
    border-radius: 20em;
    box-shadow: inset 0 2px 3px rgba(0,0,0,.2);
    background: #CDD1DA;
    transition: .2s;
}

.calc-checkbox__label:after {
    display: block;
    position: absolute;
    left: .15em;
    content: "";
    width: 1.2em;
    height: 1.2em;
    background: #ededed;
    box-sizing: border-box;
    border-radius: 50%;
    box-shadow: 0 2px 5px rgba(0,0,0,.3);
    transition: .2s;
}

.calc-checkbox__checkbox:checked + .calc-checkbox__label:after {
    transform: translateX(100%);
}

.calc-checkbox__checkbox:checked + .calc-checkbox__label:before {
    background: var(--orange-lvl-0);
}

/* Чекбокс-дропдаун */

.checkbox-dropdown__content {
    display: none;
}

.checkbox-dropdown__content.active {
    display: block;
}

/* Радио-кнопки-картинки */

.calc-radio-img {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}

.calc-radio-img__container {
    width: 45%;
    margin-bottom: 1em;
}

.calc-radio-img__btn {
    position: absolute;
    visibility: hidden;
}

.calc-radio-img__box {
    display: block;
    position: relative;
    cursor: pointer;

}

.calc-radio-img__box-wrapper {
    display: block;
    border: 2px solid #dcdcdc;
    border-radius: .3125em;
    overflow: hidden;
}

.calc-radio-img__box-border {
    display: block;
    position: relative;
    box-sizing: content-box;
    padding-top: 100%;
}

.calc-radio-img__img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.calc-radio-img__img-zoom {
    position: absolute;
    bottom: 100%;
    right: 100%;
    width: 2em;
    height: 2em;
    border-radius: 50%;
    /* clean-css ignore:start */
    background: url("../img/svg/zoom.svg") center no-repeat #ff7200;
    opacity: .7;
    background-size: 75%;
    /* clean-css ignore:end */
    z-index: 2;
    transition: .2s;
}

.calc-radio-img__box:hover .calc-radio-img__img-zoom{
    transform: translate(calc(100% + 5px), calc(100% + 5px));
}

.calc-radio-img__box:hover .calc-radio-img__img-zoom:hover {
    background-color: #ff7200;
    opacity: 1;
    transform: translate(calc(100% + 5px), calc(100% + 5px)) scale(1.1);

}

.calc-radio-img__text {
    position: absolute;
    bottom: 0;
    box-sizing: border-box;
    width: 100%;
    text-align: center;
    padding: .3125em .1875em;
    background: rgba(0, 0, 0, .4);
    color: #fff;
}

.calc-radio-img__btn:checked + .calc-radio-img__box:before {
    content: "";
    position: absolute;
    z-index: 1;
    top: -.625em;
    right: -.625em;
    width: 1.5em;
    height: 1.5em;
    border-radius: 50%;
    /* clean-css ignore:start */
    background: url("../img/svg/check-circle.svg") 97% no-repeat #fff;
    /* clean-css ignore:end */
    background-position: center;
}

.calc-radio-img__btn:checked + .calc-radio-img__box .calc-radio-img__box-wrapper {
    border-color: var(--orange-lvl-1);
}

.calc-radio-img__box:hover .calc-radio-img__box-wrapper {
    border-color: #FFA223;
}

/* Заголовок блоков калькулятора */
.calc-title {
    display: block;
    font-size: 1.375em;
    font-weight: 300;
}

.calc-title_center {
    text-align: center;
}

.calc-link {
    color: var(--orange-lvl-1);
    text-decoration: underline;
    white-space: nowrap;
}

/* Блок с цветами */

.calc-colors {
    display: flex;
    flex-wrap: wrap;
}

.calc-colors__item {
    width: 16%;
    margin: 2%;
}

.calc-colors__radio {
    position: absolute;
    overflow: hidden;
    opacity: 0;
    visibility: hidden;
}

.calc-colors__radio:checked + .calc-colors__color {
    border: 2px solid var(--orange-lvl-1);

}

.calc-colors__color {
    display: block;
    position: relative;
    width: 100%;
    border: 2px solid transparent;
    padding-top: calc(100% - 4px);
    border-radius: 5px;
    box-sizing: border-box;
    cursor: pointer;
}

.calc-colors__color:hover {
    border: 2px solid var(--orange-lvl-0);
}

.calc-colors__radio:checked + .calc-colors__color:after {
    content: "";
    position: absolute;
    z-index: 1;
    top: -.625em;
    right: -.625em;
    width: 1.5em;
    height: 1.5em;
    border-radius: 50%;
    /* clean-css ignore:start */
    background: url("../img/svg/check-circle.svg") center no-repeat #fff;
    /* clean-css ignore:end */
    background-size: 97%;

}

pre {
    min-height: 10000px;
}
