.mainFaqBlock {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.mainFaqBlock__title {
    color: black;
    border-bottom: 1px solid black;
    margin-top: 25px;
    margin-bottom: 25px;
}

.mainFaqBlock__title:hover {
    color: rgb(255, 114, 0);
    border-bottom: 1px solid rgb(255, 114, 0);
}

.mainFaqWrapper {
    width: 80%;
    box-sizing: border-box;
}

@media screen and (max-width: 959px) {

    .mainFaqWrapper {
        width: 90%;
    }

}

@media screen and (max-width: 660px) {

    .mainFaqWrapper {
        width: 100%;
        padding-right: 15px;
    }
}