
.modalWindow {
    background-color: #292929;
    border: 4px solid rgb(255, 114, 0);
    border-radius: 15px;
    max-width: 500px;
    width: 90%;
    padding-left: 35px;
    padding-right: 35px;
}

.modalForm {
    display: flex;
    flex-direction: column;
}

.modalForm__form {
    display: flex;
    flex-direction: column;
}



.modalWindow .fancybox-close-small:after {
    color: rgb(255, 114, 0);
    font-size: 45px;
}

@media screen and (max-width: 400px) {

    .modalWindow {
        padding-left: 10px;
        padding-right: 10px;
    }
}