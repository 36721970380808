.calculator {
    padding-top: 25px;
}

.calc-step__left,
.calc-step__right {
    width: 50%;
    box-sizing: border-box;
    position: relative;
    padding: 25px 0;
}

.calc-step__wrapper {
    display: flex;
    width: 100%;
}

.calc-step__left {
    padding-left: 70px;
    padding-right: 45px;
}

.calc-step__right {
    padding-left: 45px;
    padding-right: 70px;
}

.calc-step__form {
    width: 100%;
    box-sizing: border-box;
}

.calc-step_align-center {
    display: flex;
    align-items: center;
}

.calc-step__main-img {
    width: 100%;
}

.calc-step__title-label {
    font-weight: 300;
    font-size: 22px;
    color: #747373;
}

.calc-step__input-text {
    padding: 7px;

    font-weight: 300;
    font-size: 22px;
    color: #292929;
    border: 1px solid #dcdcdc;
    border-radius: 3px;
    text-align: center;
}

.margin-bm-5 {
    display: block;
    margin-bottom: 5px;
}

.margin-bm-15 {
    display: block;
    margin-bottom: 15px;
}

.calc-step__input-text:focus {
    border: 1px solid #ff7200;
}

.calc-step__input-text:focus:placeholder-shown::placeholder {
    color: transparent;
}

.calc-step__note {
    display: block;
    margin-top: 9px;
    text-align: center;
    font-weight: 300;
    font-size: 16px;
    color: #aeadad;
}

.calc-control {
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 0 3%;
    margin-bottom: 30px;
}

.calc-control__btn {
    border: none;
    padding: 10px 40px;
    border-radius: 100px;
    font-size: 24px;
    color: #ffffff;
}

.calc-control__btn i {
    position: relative;
    top: 2px;
}

.calc-control__btn_back {
    background: #dcdcdc;
    visibility: visible;
    opacity: 1;
}

.calc-control__btn_back.slick-disabled {
    opacity: 0;
    visibility: hidden;
}

.calc-control__btn_back i {
    margin-right: 15px;
}

.calc-control__btn_back:hover {
    background: #cecece;
    cursor: pointer;
}

.calc-control__btn_next {
    background: #ff7200;
    visibility: hidden;
    opacity: 0;
}

.calc-control__btn_active {
    opacity: 1;
    visibility: visible;
}

.calc-control__btn_buy {
    display: none;
    background: #ff7200;
}

.calc-control__btn_buy i {
    margin-left: 15px;
}

.calc-control__btn_buy:hover {
    background: #ff9946;
    cursor: pointer;
}

.calc-control__btn_next.slick-disabled {
    display: none;
}

.calc-control__btn_next.slick-disabled + .calc-control__btn_buy {
    display: block;
}

.calc-control__btn_next i {
    margin-left: 15px;
}

.calc-control__btn_next:hover {
    background: #ff9946;
    cursor: pointer;
}

.calc-step__input-radio {
    display: none;
}

.radio-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    background: #dcdcdc;
    width: 17px;
    height: 17px;
    box-sizing: border-box;
    border-radius: 50%;
    margin-right: 15px;
    cursor: pointer;
}

.radio-btn__mark {
    visibility: hidden;
    background: #ff7200;
    width: 9px;
    height: 9px;
    box-sizing: border-box;
    border-radius: 50%;
}

.calc-step__input-radio:checked + .calc-step__label-radio .radio-btn__mark {
    visibility: visible
}

.calc-step__label-radio {
    display: flex;
    align-items: center;
    font-weight: 300;
    font-size: 22px;
    color: #292929;
    margin-bottom: 16px;
    cursor: pointer;
}

/*
	<input class="calc-step__input-checkbox" id="calc-step__luvers-tarpaulin" type="checkbox">
	<label class="calc-step__label-checkbox" for="calc-step__luvers-tarpaulin"><div class="checkbox-btn"><i class="checkbox-btn__mark fa fa-check" aria-hidden="true"></i></div>установить люверсы</label>
*/

.calc-step__input-checkbox {
    display: none;
}

.checkbox-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    background: #dcdcdc;
    width: 17px;
    height: 17px;
    box-sizing: border-box;
    border-radius: 2px;
    margin-right: 15px;
    cursor: pointer;
}

.checkbox-btn__mark {
    visibility: hidden;
    color: #ff7200;
    font-size: 14px;
}

.calc-step__input-checkbox:checked + .calc-step__label-checkbox .checkbox-btn__mark {
    visibility: visible
}

.calc-step__label-checkbox {
    display: flex;
    align-items: center;
    font-weight: 300;
    font-size: 22px;
    color: #292929;
    margin-bottom: 16px;
    cursor: pointer
}

.calc-step__select {
    width: 100%;
    display: block;
    padding: 7px 40px 7px 10px;
    box-sizing: border-box;
    background: #ffffff;
    font-weight: 300;
    font-size: 22px;
    color: #292929;
    border: 1px solid #dcdcdc;
    border-radius: 3px;
    background-image: url(../../img/images/calculators/chevron-arrow-down.svg);
    background-size: 20px;
    background-repeat: no-repeat;
    background-position: 95%;
    margin-bottom: 15px;
}

.calc-step__select option {
    box-sizing: border-box;
}

.calc-step__select:focus {
    border: 1px solid #ff7200;
}

.calc-step__select_width-50 {
    width: calc(50% - 5px);
    box-sizing: border-box;
}

.calc-step__select-block {
    display: none;
    flex-wrap: wrap;
    justify-content: space-between;
}

.calc-step__select-block_active {
    display: flex;
}

.calc-step__select-mini-label {
    font-weight: 300;
    font-size: 14px;
    color: #747373;
    width: calc(50% - 5px);
}

.finish-table {
    font-weight: 300;
    font-size: 24px;
    margin-top: 25px;
}

.finish-table__td {
    color: #747373;
    padding-bottom: 5px;
}

.finish-table__td:last-of-type {
    color: #292929;
    padding-left: 15px;
}

.hidden {
    display: none;
}

.calc-add-done__title {
    font-weight: 300;
    font-size: 22px;
    color: #292929;
    margin-bottom: 15px;
}

.calc-add-done__img-done {
    display: block;
    width: 45px;
    margin-bottom: 15px;
}

.calc-add-done__link {
    display: block;
    font-size: 22px;
    background: #ff7200;
    border-radius: 7px;
    color: #ffffff;
    padding: 10px 15px;
}

.calc-add-done__link:hover {
    background: #ff9946;
}

.calc-add-done {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 15px;
}

.calc-step__input-radio-color {
    display: none;
}

.radio-btn-color {
    width: 20px;
    height: 20px;
    margin-right: 15px;
    box-sizing: border-box;
    border-radius: 3px;
}

.calc-step__input-radio-color + .calc-step__label-radio-color .radio-btn-color {
    border: 2px solid transparent;
}

.calc-step__input-radio-color:checked + .calc-step__label-radio-color .radio-btn-color {
    border: 2px solid #ff7200;

}

.calc-step__label-radio-color {
    display: flex;
    align-items: center;
    font-weight: 300;
    font-size: 22px;
    color: #292929;
    margin-bottom: 15px;
    cursor: pointer;
}

.calc-step__label-checkbox {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
}

.check-btn {
    background: #dcdcdc;
    width: 20px;
    height: 20px;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 3px;
    margin-right: 15px;
}

.check-btn .check-btn__mark {
    font-size: 14px;
    color: #ff7200;
    visibility: hidden;
}

.calc-step__input-checkbox:checked + .calc-step__label-checkbox .check-btn__mark {
    visibility: visible;
}

img.calc-step__img-shtora,
img.calc-step__fasteners-img {
    display: none;
}

img.calc-step__img-shtora.active,
img.calc-step__fasteners-img.active {
    display: block;
}

.calc-segment_standart {
    display: none;
}

.calc-segment_premium {
    display: none;
}

.calc-segment_standart_active {
    display: block;
}

.calc-segment_premium_active {
    display: block;
}

.calc-step__color-block {
    display: none;
}

.calc-step__color-block_active {
    display: block;
}

.pvh-germany-color,
.pvh-korea-color {
    display: none;
}

.pvh-germany-color_active,
.pvh-korea-color_active {
    display: flex;
}

img.calc-step__main-img_material,
img.calc-step__main-img_moskit {
    display: none;
}

img.calc-step__main-img_active {
    display: block;
}

.calc-step__additional-block_hidden {
    display: none;
}

.window-notification_red {
    color: red;
}

img.calc-step__main-img_razdel,
img.calc-step__main-img_size {
    display: none;
}

img.calc-step__main-img_razdel-active {
    display: block;
}

img.window-mounting {
    display: none;
}

img.window-mounting_active {
    display: block;
}

.calc-step__select-block_incision,
.calc-step__select-block_window-door {
    display: none;
}

.calc-step__select-block_incision-active {
    display: block;
}

.calc-pass-message {
    display: none;
}

.calc-pass-message_active {
    display: block;
}

.red-error {
    color: red;
}

.window-door-fix {
    display: none;
}

img.calc-step__incision-img {
    display: none;
}

img.calc-step__incision-img_active {
    display: block;
}

@media screen and (max-width: 767px) {
    .calc-step__left {
        width: 100%;
        order: 2;
        padding-left: 15px;
        padding-right: 15px;
    }

    .calc-step__right {
        width: 100%;
        order: 1;
        margin-bottom: 25px;
        justify-content: center;
        padding-left: 15px;
        padding-right: 15px;
    }

    .calc-step__main-img {
        width: 75%;
    }

    .calc-step__title {
        font-size: 18px;
    }

    .calc-step__title-label {
        font-size: 16px;
    }

    .calc-step__input-text {
        font-size: 16px;
        padding: 5px;
    }

    .calc-control__btn {
        font-size: 16px;
        padding: 5px 20px
    }

    .calc-step__note {
        font-size: 14px;
    }

    .calc-step__label-radio {
        font-size: 16px;
    }

    .calc-step__select {
        font-size: 16px;
        background-size: 15px;
    }

    .finish-table {
        font-size: 16px;
    }

    .calc-add-done__title {
        font-size: 16px;
        margin-bottom: 10px;
    }

    .calc-add-done__img-done {
        width: 35px;
        margin-bottom: 10px;
    }

    .calc-add-done__link {
        font-size: 16px;
    }
}

.width-full {
    width: 100%;
    box-sizing: border-box;
}

img.calc-step__material-prom-shtori {
    display: none;
}

img.calc-step__material-shtori {
    display: none;
}

img.calc-step_img_active {
    display: block;
}

img.calc-step_propitka {
    width: 60%;
}

img.calc-step_vo,
img.calc-step_op {
    display: none;
    position: absolute;
    right: 12%;
    bottom: 7%;
}

img.propitka-active {
    display: block;
}

.prom-shtori-error {
    display: none;
}

.shtori-error {
    display: none;
}

.shtori-error_active {
    display: block;
}

.finish-table__td_total-price {
    font-weight: 400;
    padding-top: 15px;
}

.toggle-calc-wrapper {
    display: flex;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    align-items: center;

    margin-left: 30px;
    margin-right: 30px;
    margin-bottom: 15px;
}

@media screen and (max-width: 767px) {
    .toggle-calc-wrapper {
        margin-top: 15px;
    }

    .toggle-calc-note {
        font-size: 12px;
    }
    .calc-step__wrapper {
        flex-direction: column-reverse;
    }
}

.toggle-calc-note {
    flex-grow: 1;
}

.toggle-bg {
    background: #f6f8f9;
    background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pgo8c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgdmlld0JveD0iMCAwIDEgMSIgcHJlc2VydmVBc3BlY3RSYXRpbz0ibm9uZSI+CiAgPGxpbmVhckdyYWRpZW50IGlkPSJncmFkLXVjZ2ctZ2VuZXJhdGVkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjAlIiB5MT0iMCUiIHgyPSIwJSIgeTI9IjEwMCUiPgogICAgPHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iI2Y2ZjhmOSIgc3RvcC1vcGFjaXR5PSIxIi8+CiAgICA8c3RvcCBvZmZzZXQ9IjUwJSIgc3RvcC1jb2xvcj0iI2U1ZWJlZSIgc3RvcC1vcGFjaXR5PSIxIi8+CiAgICA8c3RvcCBvZmZzZXQ9IjUxJSIgc3RvcC1jb2xvcj0iI2Q3ZGVlMyIgc3RvcC1vcGFjaXR5PSIxIi8+CiAgICA8c3RvcCBvZmZzZXQ9IjEwMCUiIHN0b3AtY29sb3I9IiNmNWY3ZjkiIHN0b3Atb3BhY2l0eT0iMSIvPgogIDwvbGluZWFyR3JhZGllbnQ+CiAgPHJlY3QgeD0iMCIgeT0iMCIgd2lkdGg9IjEiIGhlaWdodD0iMSIgZmlsbD0idXJsKCNncmFkLXVjZ2ctZ2VuZXJhdGVkKSIgLz4KPC9zdmc+);
    background: -moz-linear-gradient(top, #f6f8f9 0%, #e5ebee 50%, #d7dee3 51%, #f5f7f9 100%);
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #f6f8f9), color-stop(50%, #e5ebee), color-stop(51%, #d7dee3), color-stop(100%, #f5f7f9));
    background: -webkit-linear-gradient(top, #f6f8f9 0%, #e5ebee 50%, #d7dee3 51%, #f5f7f9 100%);
    background: -o-linear-gradient(top, #f6f8f9 0%, #e5ebee 50%, #d7dee3 51%, #f5f7f9 100%);
    background: -ms-linear-gradient(top, #f6f8f9 0%, #e5ebee 50%, #d7dee3 51%, #f5f7f9 100%);
    background: linear-gradient(to bottom, #f6f8f9 0%, #e5ebee 50%, #d7dee3 51%, #f5f7f9 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#f6f8f9', endColorstr='#f5f7f9', GradientType=0);

    -khtml-border-radius: 30px;
    -moz-border-radius: 30px;
    -ms-border-radius: 30px;
    -o-border-radius: 30px;
    -webkit-border-radius: 30px;
    border-radius: 30px;

    -khtml-box-shadow: 0 1px 0 #fff, inset 0 0 2px #3f4c6b, inset 0 1px 0 #d7dee3, inset 0 1px 5px #d7dee3;
    -moz-box-shadow: 0 1px 0 #fff, inset 0 0 2px #3f4c6b, inset 0 1px 0 #d7dee3, inset 0 1px 5px #d7dee3;
    -ms-box-shadow: 0 1px 0 #fff, inset 0 0 2px #3f4c6b, inset 0 1px 0 #d7dee3, inset 0 1px 5px #d7dee3;
    -o-box-shadow: 0 1px 0 #fff, inset 0 0 2px #3f4c6b, inset 0 1px 0 #d7dee3, inset 0 1px 5px #d7dee3;
    -webkit-box-shadow: 0 1px 0 #fff, inset 0 0 2px #d7dee3, inset 0 1px 0 #d7dee3, inset 0 1px 5px #d7dee3;
    box-shadow: 0 1px 0 #fff, inset 0 0 2px #d7dee3, inset 0 1px 0 #d7dee3, inset 0 1px 5px #d7dee3;

    display: block;
    float: left;
    height: 25px;
    position: relative;
    width: 50px;
    margin-right: 20px;
}

.toggle-bg input {
    height: 25px;
    left: 0;
    margin: 0;
    opacity: 0;
    padding: 0;
    position: absolute;
    top: 0;
    width: 50px;
    z-index: 2;
    zoom: 1;
    filter: alpha(opacity=0);
    cursor: pointer;

}

.switch {
    background: #ffffff;
    background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pgo8c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgdmlld0JveD0iMCAwIDEgMSIgcHJlc2VydmVBc3BlY3RSYXRpbz0ibm9uZSI+CiAgPHJhZGlhbEdyYWRpZW50IGlkPSJncmFkLXVjZ2ctZ2VuZXJhdGVkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgY3g9IjUwJSIgY3k9IjUwJSIgcj0iNzUlIj4KICAgIDxzdG9wIG9mZnNldD0iMCUiIHN0b3AtY29sb3I9IiNmZmZmZmYiIHN0b3Atb3BhY2l0eT0iMSIvPgogICAgPHN0b3Agb2Zmc2V0PSI1MCUiIHN0b3AtY29sb3I9IiNmZWZlZmUiIHN0b3Atb3BhY2l0eT0iMSIvPgogICAgPHN0b3Agb2Zmc2V0PSI1MSUiIHN0b3AtY29sb3I9IiNmZGZkZmQiIHN0b3Atb3BhY2l0eT0iMSIvPgogICAgPHN0b3Agb2Zmc2V0PSIxMDAlIiBzdG9wLWNvbG9yPSIjZmZmZmZmIiBzdG9wLW9wYWNpdHk9IjEiLz4KICA8L3JhZGlhbEdyYWRpZW50PgogIDxyZWN0IHg9Ii01MCIgeT0iLTUwIiB3aWR0aD0iMTAxIiBoZWlnaHQ9IjEwMSIgZmlsbD0idXJsKCNncmFkLXVjZ2ctZ2VuZXJhdGVkKSIgLz4KPC9zdmc+);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffffff', endColorstr='#ffffff', GradientType=1);

    border-radius: 30px;

    box-shadow: 0 1px 1px #65727b, 0 0 1px #b6bdc2;

    display: block;
    float: left;
    height: 25px;
    left: -1px;
    position: relative;
    top: 0;

    transition: left .2s ease;

    width: 25px;
    z-index: 1;

}

.toggle-bg input:checked ~ .switch {
    left: -1px;
}

.toggle-bg input ~ :checked ~ .switch {
    left: 26px;
}

.toggle-on:checked + .switch {
    background: #ff7200;
}

.toggle-bg input:checked {
    z-index: 0;
}

.toggle-bg .toggle-off:not(:checked) {
    background: red;
}

.no-calc {
    display: none;
}
