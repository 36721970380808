.fourProductItems {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
}

.fourProductItems_wrapper {
    width: 25%;
    position: relative;
    display: block;
}

.fourProductItems_wrapper33 {
    width: 33.33%;
}

.fourProductItems_padding {
    padding: 15px;
}

.fourProductItems_block {
    position: relative;
    overflow: hidden;
}

.fourProductItems_block > img {

    will-change: scroll-position;
    position: relative;
    width: 100%;
    display: block;
}

.fourProductItems_textBlock {
    width: 100%;
    position: absolute;
    height: 100%;
    top: 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
}

.fourProductItems_textBlock > h4 {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 90%;
    box-sizing: border-box;
    color: #ffffff;
    font-weight: 700;
    font-size: 20px;
    min-height: 50px;
    margin-bottom: 25px;
    position: relative;
    text-align: center;
    padding: 10px 20px;
    border-radius: 300px;
    background:  rgba(0, 0, 0, .5);
    transition: all ease .2s;
}

.fourProductItems_textBlock > p {
    color: #ffffff;
    font-weight: 300;
    font-size: 16px;
    overflow: hidden;
    position: relative;
    max-height: 0px;
    text-align: right;
    padding-right: 10%;
    padding-left: 15%;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, .5);
}

.fourProductItems_shadow {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
}

@media screen and (min-width: 768px) {

    .fourProductItems_wrapper:hover .fourProductItems_shadow {
        background: rgba(0, 0, 0, 0.5);
    }

    .fourProductItems_wrapper:hover .fourProductItems_textBlock > h4 {
        background: rgba(0,0,0,0);
    }

    .fourProductItems_wrapper:hover .fourProductItems_block {
        cursor: pointer;
    }

    .fourProductItems_wrapper:hover .fourProductItems_textBlock > h4 {
        cursor: pointer;
    }

    .fourProductItems_wrapper:hover .fourProductItems_textBlock > p {
        max-height: 200px;
        cursor: pointer;
    }
}

.fourProductItems_calcRound,
.fourProductItems_saleRound {
    width: 45px;
    height: 45px;
}

.fourProductItems_calcRound {
    display: none;
    position: absolute;
    z-index: 98;
    top: 5px;
    left: 5px;
    border-radius: 50%;
    background-color: rgb(255, 114, 0);
}

.fourProductItems_calcRound-popup {
    display: none;
    position: absolute;
    top: 140%;
    left: 4px;
    background-color: rgb(242, 242, 242);
    border: 2px solid rgb(255, 114, 0);
    padding: 5px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, .2);
    border-radius: 3px;
}

.fourProductItems_calcRound-popup:after,
.fourProductItems_calcRound-popup:before {
    bottom: 100%;
    left: 45%;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
}

.fourProductItems_calcRound-popup:after {
    border-color: rgba(136, 183, 213, 0);
    border-bottom-color: rgb(242, 242, 242);
    border-width: 12px;
    margin-left: -42px;
}

.fourProductItems_calcRound-popup:before {
    border-color: rgba(194, 225, 245, 0);
    border-bottom-color: rgb(255, 114, 0);
    border-width: 14px;
    margin-left: -44px;
}

.fourProductItems_calcRound img {
    position: relative;
    width: 50%;
}

.fourProductItems_saleRound {
    display: none;
    position: absolute;
    z-index: 98;
    top: 5px;
    right: 5px;
    border-radius: 50%;
    background-color: rgb(255, 48, 48);
}

.fourProductItems_saleRound span {
    display: inline-block;
    font-size: 16px;
    font-weight: 700;
    color: #ffffff;
}

.activeRound {
    display: flex;
    justify-content: center;
    align-items: center;
}

.fourProductItems_margin {
    margin-top: 25px;
}

@media screen and (max-width: 1169px) {


    .fourProductItems_saleRound span {
        font-size: 14px;
    }


    .fourProductItems_textBlock > h4 {
        font-size: 18px;
    }
    .fourProductItems_textBlock > p {
        font-size: 14px;
    }
}

@media screen and (min-width: 768px) {
    .fourProductItems_calcRound:hover .fourProductItems_calcRound-popup {
        display: inline-block;
    }

    .fourProductItems_calcRound:hover {
        cursor: pointer;
    }

    .fourProductItems_calcRound:hover {
        background-color: rgb(255, 135, 38)
    }
}

@media screen and (max-width: 768px) {
    .fourProductItems_wrapper {
        width: 33.3%;
    }
}

@media screen and (max-width: 570px) {
    .fourProductItems_wrapper {
        width: 50%;
    }

    .fourProductItems_calcRound,
    .fourProductItems_saleRound {
        width: 35px;
        height: 35px;
    }
}

@media screen and (min-width: 570px) and (max-width: 1169px) {
    .fourProductItems_calcRound,
    .fourProductItems_saleRound {
        width: 35px;
        height: 35px;
    }
}

.fourItemProductSlider,
.mainBenefitsSlider,
.mainSalesSlider,
.mainPopularSlider,
.mainOurWorksSlider,
.mainNewsSlider,
.mainReviewsSlider,
.mainPartnersSlider {
    display: block;
}