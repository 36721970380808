/*-------- Гарантии ---------*/

.guarantees-benefits-block {
    display: flex;
    flex-direction: column;
    width: 75%;
    margin: 0 auto;
}

.guarantees-benefit {
    display: flex;
    background: #ffffff;
    border: 2px solid #ff7200;
    border-radius: 300px;
    padding: 25px 75px;
    box-sizing: border-box;
    margin-bottom: 25px;
    justify-content: space-between;
}

.guarantees-benefit__img-wrapper {
    flex: 0 0 120px;
    margin-right: 25px;
    display: flex;
    align-items: center;
}

.guarantees-benefit__img {
    width: 100%;
}

.guarantees-benefit__text-block {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    flex-grow: 2;
}

.guarantees-benefit__title {
    font-size: 28px;
    text-transform: uppercase;
    color: #ff7200;
    margin-bottom: 20px;
}

.guarantees-benefit__text {
    font-weight: 300;
    font-size: 26px;
    color: #747373;
}

.page-guarantees {
    padding: 75px 15px;
}

.guarantees-benefits-block {
    margin-bottom: 80px;
}

.guarantees-benefit-paragraph {
    font-weight: 300;
    font-size: 30px;
    color: #292929;
    text-align: center;
    line-height: 1.2;
}

.mar-tp-70 {
    margin-top: 70px;
}

@media screen and (max-width: 1169px) {
    .guarantees-benefit-paragraph {
        font-size: 25px;
    }

    .guarantees-benefit__img-wrapper {
        flex: 0 0 80px;
    }

    .guarantees-benefit__title {
        font-size: 23px;
    }

    .guarantees-benefit__text {
        font-size: 21px;
    }
}

@media screen and (max-width: 959px) {
    .guarantees-benefit-paragraph {
        font-size: 20px;
    }

    .guarantees-benefit__img-wrapper {
        flex: 0 0 65px;
    }

    .guarantees-benefit__title {
        font-size: 16px;
    }

    .guarantees-benefit__text {
        font-size: 16px;
    }
}

@media screen and (max-width: 650px) {
    .page-guarantees {
        padding: 40px 15px;
    }

    .guarantees-benefits-block {
        width: 85%;
        margin-bottom: 0;
    }

    .guarantees-benefit-paragraph {
        font-size: 16px;
    }

    .guarantees-benefit__img-wrapper {
        flex: 0 0 45px;
        margin-right: 20px;
    }

    .guarantees-benefit__title {
        font-size: 16px;
        margin-bottom: 5px;
    }

    .guarantees-benefit__text {
        font-size: 16px;
    }

    .guarantees-benefit {
        padding: 18px 35px;
    }

    .company-title.mar-bm-45 {
        margin-bottom: 15px;
    }

    .company-title.mar-tp-70 {
        margin-top: 25px;
    }
}

@media screen and (max-width: 415px) {
    .page-guarantees {
        padding: 35px 15px;
    }

    .guarantees-benefits-block {
        width: 100%;
        margin-bottom: 0;
    }

    .guarantees-benefit-paragraph {
        font-size: 14px;
    }

    .guarantees-benefit__img-wrapper {
        flex: 0 0 25px;
        margin-right: 6px;
    }

    .guarantees-benefit__title {
        font-size: 12px;
        margin-bottom: 5px;
    }

    .guarantees-benefit__text {
        font-size: 12px;
    }

    .guarantees-benefit {
        padding: 10px 15px;
    }

    .company-title.mar-bm-45 {
        margin-bottom: 15px;
    }

    .company-title.mar-tp-70 {
        margin-top: 25px;
    }
}