/* --------- Корзина -------- */

.page-basket {
    padding-top: 45px;
    padding-left: 15px;
    padding-right: 15px;
}

.basket-table {
    width: 100%;
    border-collapse: collapse;
}

.bt-head {
    background: #f6f4f4;
}

.bt-th {
    padding: 13px 10px;
    font-size: 22px;
    color: #747373;
}

.bt-product {
    border-bottom: 2px solid #dcdcdc;
}

.bt-product__td {
    vertical-align: top;
    padding-top: 15px;
    color: #292929;
    font-weight: 300;
    font-size: 24px;
}

.bt-product__center {
    text-align: center;
}

.bt-product__sale {
    color: #ff7200;
}

.bt-product__delete .fa-times {
    color: #dcdcdc;
    font-size: 28px;
    line-height: 0;
    cursor: pointer;
}

.bt-product__delete .fa-times:hover {
    color: #ff7200;
}

.bt-product__name {
    padding-bottom: 10px;
    max-width: 400px;
}

.bt-product__name,
.bt-product__name-link {
    color: #292929;
    font-weight: 700;
}

.bt-product__name-link:hover {
    color: #ff7200;
}

.bt-product-options {
    padding-bottom: 20px;
}

.bt-product-options__atr {
    font-weight: 300;
    font-size: 22px;
    color: #292929;
    padding-right: 5px;
    padding-bottom: 10px;
}

.bt-product-options__atr-name {
    font-weight: 300;
    font-size: 22px;
    color: #747373;
    padding-right: 15px;
    padding-bottom: 10px;
}

.bt-bottom-block {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 15px;
}

.bt-counter__name {
    font-weight: 300;
    font-size: 22px;
    color: #747373;
    margin-right: 20px;
}

.bt-counter__minus,
.bt-counter__plus {
    color: #ff7200;
    font-size: 22px;
    padding-left: 7px;
    padding-right: 7px;
    cursor: pointer;
}

.bt-counter__minus:hover,
.bt-counter__plus:hover {
    color: #ff9946;
}

.bt-counter__number {
    color: #292929;
    font-weight: 300;
    font-size: 24px;
}

.bt-counter__control-block {
    display: inline-block;
    position: relative;
}

.bt-counter__preloader {
    position: absolute;
    top: 0;
    display: none;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.bt-counter__preloader-img {
    width: 20px;
}

.bt-counter__control-block_preload .bt-counter__number {
    visibility: hidden;
}

.bt-counter__control-block_preload .bt-counter__preloader {
    display: flex;
}

.bt-counter__control-block_preload i {
    color: #dcdcdc;
    cursor: default;
}

.bt-reduce {
    font-weight: 300;
    font-size: 22px;
    color: #ff7200;
    cursor: pointer;
}

.bt-reduce:hover {
    color: #ff9946;
}

.bt-reduce__text {
    padding-right: 10px;
    cursor: pointer;
}

.bt-reduce__icon_rotate {
    transform: rotate(180deg);
}

.bt-product-options__tr span {
    display: block;
}

.bt-product-options .bt-product-options__tr:nth-of-type(3) ~ .bt-product-options__tr {
    display: none;
}

.bt-product-options_active .bt-product-options__tr:nth-of-type(3) ~ .bt-product-options__tr {
    display: table-row;
}

.bt-product__img-link {
    display: inline-block;
    width: 70%;
    position: relative;
}

.bt-product__img {
    display: block;
    width: 100%;
}

.bt-product__img-bg-logo {
    background-color: rgb(71, 72, 72);
}

.bt-product__calc-round {
    background: #ff7200;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: -5px;
    left: -5px;
    z-index: 1;
}

.bt-product__calc-round img {
    display: block;
    width: 55%;
}

.bt-product-mob {
    display: none;
}

.bt-order-price-block {
    background: #f6f4f4;
    margin-top: 30px;
    display: flex;
    justify-content: space-between;
    padding: 30px 70px;
    box-sizing: border-box;
}

.bt-order-price {
    width: 415px;
}

.bt-order-price__old,
.bt-order-price__total,
.bt-order-price__eco {
    display: flex;
    justify-content: space-between;
}

.bt-order-price__old {
    font-weight: 300;
    font-size: 24px;
    color: #747373;
    margin-bottom: 40px;
}

.bt-order-price__total {
    font-weight: 700;
    font-size: 30px;
    color: #ff7200;
}

.bt-order-price__eco {
    font-weight: 300;
    font-size: 24px;
    color: #ff7200;
    margin-top: 15px;
}

.bt-order-price__through {
    text-decoration: line-through;
}

.bt-buy-btn-block {
    display: flex;
    justify-content: center;
    align-items: center;
}

.bt-buy-btn {
    display: block;
    font-size: 22px;
    color: #fefefe;
    padding: 20px 70px;
    background: #ff7200;
    border-radius: 50px;
    margin-top: 48px;
    margin-bottom: 45px;
    text-transform: uppercase;
    cursor: pointer;
}

.bt-buy-btn:hover {
    background: #ff9946;
}

.bt-order-promo__title {
    display: block;
    font-weight: 300;
    font-size: 18px;
    margin-bottom: 15px;
}

.bt-order-promo__form {
    display: flex;
    flex-direction: column;
}

.bt-order-promo__input {
    width: 100%;
    padding: 7px 15px;
    font-weight: 300;
    font-size: 18px;
    margin-bottom: 15px;
    outline: none;
    color: #ff7200;
    background: #ffffff;
    border: 1px solid #dcdcdc;
    box-sizing: border-box;
}

.bt-order-promo__submit {
    display: block;
    background: #ff7200;
    border-radius: 7px;
    color: #fefefe;
    padding: 5px 10px;
    font-size: 16px;
    outline: none;
    text-transform: uppercase;
    box-sizing: border-box;
    cursor: pointer;
    border: none;
}

.bt-order-promo__submit:hover {
    background: #ff9946;
}

.page-empty-basket {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.page-empty-basket__title {
    font-size: 50px;
    color: #dcdcdc;
    margin-top: 25px;
}

.page-empty-basket__icon {
    font-size: 200px;
    color: #dcdcdc;
    margin-bottom: 25px;
}

.page-empty-basket__link-catalog {
    background: #ff7200;
    font-size: 26px;
    color: #fefefe;
    padding: 15px 50px;
    border-radius: 100px;
    margin-bottom: 75px;
    box-sizing: border-box;
}

.page-empty-basket__link-catalog:hover {
    background: #ff9946;
}

@media screen and (max-width: 959px) {
    .bt-th {
        font-size: 18px;
    }

    .bt-product__td {
        font-size: 20px;
    }

    .bt-product__delete .fa-times {
        font-size: 24px;
    }

    .bt-product-options__atr-name {
        font-size: 18px;
    }

    .bt-product-options__atr {
        font-size: 18px;
    }

    .bt-counter__name {
        font-size: 18px;
    }

    .bt-counter__minus,
    .bt-counter__plus {
        font-size: 18px;
    }

    .bt-counter__number {
        font-size: 20px;
    }

    .bt-reduce {
        font-size: 18px;
    }

    .bt-order-price__old {
        font-size: 20px;
        margin-bottom: 25px;
    }

    .bt-order-price__total {
        font-size: 24px;
    }

    .bt-order-price__eco {
        font-size: 20px;
        margin-bottom: 10px;
    }

    .bt-order-price {
        width: 250px;
    }

    .bt-order-promo__title {
        font-size: 14px;
    }

    .bt-order-promo__input {
        font-size: 14px;
    }

    .bt-order-promo__submit {
        font-size: 14px;
    }

    .bt-buy-btn {
        font-size: 18px;
        padding: 15px 50px;
        margin: 30px 0;
    }
}

@media screen and (max-width: 767px) {

    .bt-th_mob-hid {
        display: none;
    }

    .bt-bottom-block {
        flex-direction: column;
    }

    .bt-counter {
        align-self: flex-start;
        margin-bottom: 25px;
    }

    .bt-reduce {
        align-self: flex-end;
    }

    .bt-product-mob {
        display: flex;
        width: 100%;
        justify-content: space-around;
        margin-bottom: 18px;
    }

    .bt-product-mob__col {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .bt-product-mob-col__title {
        font-size: 22px;
        color: #747373;
        margin-bottom: 19px;
    }

    .bt-product-mob-col__numb {
        font-weight: 300;
        font-size: 22px;
        color: #292929;
    }

    .bt-product-mob-col__numb_sale {
        color: #ff7200;
    }

    .bt-order-price-block {
        flex-direction: column-reverse;
        align-items: center;
        padding-left: 10px;
        padding-right: 10px;
    }

    .bt-order-price {
        width: 280px;
    }

    .bt-order-promo {
        width: 280px;
        margin-top: 30px;
    }

    .page-empty-basket__title {
        font-size: 25px;
        margin-top: 15px;
    }

    .page-empty-basket__icon {
        font-size: 100px;
        margin-bottom: 15px;
    }

    .page-empty-basket__link-catalog {
        font-size: 18px;
        padding: 15px 35px;
        margin-bottom: 40px;
    }
}

@media screen and (max-width: 414px) {
    .bt-th {
        font-size: 14px;
    }

    .bt-product__td {
        font-size: 16px;
    }

    .bt-product__delete .fa-times {
        font-size: 20px;
    }

    .bt-product-options__atr-name {
        font-size: 14px;
    }

    .bt-product-options__atr {
        font-size: 14px;
    }

    .bt-counter__name {
        font-size: 14px;
    }

    .bt-counter__minus,
    .bt-counter__plus {
        font-size: 16px;
    }

    .bt-counter__number {
        font-size: 18px;
    }

    .bt-reduce {
        font-size: 16px;
    }

    .bt-product-mob-col__title {
        font-size: 16px;
        margin-bottom: 15px;
    }

    .bt-product-mob-col__numb {
        font-size: 16px;
    }
}

@media screen and (max-width: 360px) {
    .page-basket {
        padding-top: 25px;
        padding-left: 10px;
        padding-right: 10px;
    }

    .bt-th {
        font-size: 14px;
        padding: 10px 5px;
    }

    .bt-product__td {
        font-size: 16px;
    }

    .bt-product__delete .fa-times {
        font-size: 18px;
    }

    .bt-product__img-block {
        max-width: 90px;
    }
}