/* ----- Новости ----- */

.news-container {
    margin-top: 30px;
    margin-bottom: 30px;
}

.news-block {
    display: block;
    position: relative;
    overflow: hidden;
}

.news-block-wrapper {
    padding: 10px;
    box-sizing: border-box;
}

.news-block-wrapper:nth-of-type(1),
.news-block-wrapper:nth-of-type(2),
.news-block-wrapper:nth-of-type(3) {
    float: left;

}

.news-block-wrapper:nth-of-type(4),
.news-block-wrapper:nth-of-type(5),
.news-block-wrapper:nth-of-type(6) {
    float: right;
}

@media screen and (min-width: 768px), screen and (max-width: 560px) {

    .news-block-wrapper:first-of-type .news-text__text,
    .news-block-wrapper:nth-of-type(4n) .news-text__text {
        display: block;
    }
}

.news-block__img {
    display: block;
    width: 100%;
}

.news-text {
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    padding-left: 10px;
    padding-right: 10px;
    box-sizing: border-box;
    text-align: center;
}

.news-text__date {
    display: block;
    font-size: 22px;
}

.news-text__title {
    display: block;
    font-weight: 700;
    font-size: 32px;
    cursor: pointer;
}

.news-text__text {
    display: none;
    font-weight: 300;
    font-size: 26px;
    cursor: pointer;
}

.news-text__link {
    font-size: 26px;
    text-decoration: underline;
    color: #ff7200;
}

.news-text__link:hover {
    color: #ffffff;

}

.news-block:hover .news-block__img {
    filter: blur(2px);
}

@media screen and (min-width: 768px) {
    .news-block-wrapper:nth-of-type(2),
    .news-block-wrapper:nth-of-type(3) {
        width: 25%;
    }

    .news-block-wrapper:nth-of-type(5),
    .news-block-wrapper:nth-of-type(6) {
        width: 25%;
    }

    .news-block-wrapper:first-of-type,
    .news-block-wrapper:nth-of-type(4n) {
        width: 50%;
    }

    .news-block-wrapper:nth-of-type(2) .news-text__date,
    .news-block-wrapper:nth-of-type(3) .news-text__date,
    .news-block-wrapper:nth-of-type(5) .news-text__date,
    .news-block-wrapper:nth-of-type(6) .news-text__date,
    .news-block-wrapper:nth-of-type(2) .news-text__title,
    .news-block-wrapper:nth-of-type(3) .news-text__title,
    .news-block-wrapper:nth-of-type(5) .news-text__title,
    .news-block-wrapper:nth-of-type(6) .news-text__title,
    .news-block-wrapper:nth-of-type(2) .news-text__link,
    .news-block-wrapper:nth-of-type(3) .news-text__link,
    .news-block-wrapper:nth-of-type(5) .news-text__link,
    .news-block-wrapper:nth-of-type(6) .news-text__link {
        font-size: 16px;
    }

    .news-container {
        margin-top: 60px;
        margin-bottom: 30px;
    }
}

@media screen and (max-width: 959px) {
    .news-text__date {
        font-size: 18px;
    }

    .news-text__title {
        font-size: 22px;
    }

    .news-text__text {
        font-size: 22px;
    }

    .news-text__link {
        font-size: 18px;
    }
}

@media screen and (min-width: 768px) and (max-width: 959px) {
    .news-block-wrapper:nth-of-type(2) .news-text__date,
    .news-block-wrapper:nth-of-type(3) .news-text__date,
    .news-block-wrapper:nth-of-type(5) .news-text__date,
    .news-block-wrapper:nth-of-type(6) .news-text__date,
    .news-block-wrapper:nth-of-type(2) .news-text__title,
    .news-block-wrapper:nth-of-type(3) .news-text__title,
    .news-block-wrapper:nth-of-type(5) .news-text__title,
    .news-block-wrapper:nth-of-type(6) .news-text__title,
    .news-block-wrapper:nth-of-type(2) .news-text__link,
    .news-block-wrapper:nth-of-type(3) .news-text__link,
    .news-block-wrapper:nth-of-type(5) .news-text__link,
    .news-block-wrapper:nth-of-type(6) .news-text__link {
        font-size: 14px;
    }
}

@media screen and (max-width: 768px) {
    .news-block-wrapper {
        width: 50%;
    }
}

@media screen and (max-width: 560px) {
    .news-block-wrapper {
        width: 100%;
    }

    .news-block-wrapper .news-text__text {
        display: block;
    }
}