/*-------- Контакты ---------*/

.contacts-main {
    display: flex;
}

.contacts-main-left {
    width: 50%;
    margin-top: 80px;
}

.contacts-main-right {
    width: 50%;
    margin-top: 120px;
}

.contacts__block {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-top: 30px;
}

.contact,
.contact-50w {
    display: flex;
    flex: 1 0 10%;
    box-sizing: border-box;
    margin-bottom: 40px;
}

.contact__icon {
    font-size: 45px;
    color: #ff7200;
    width: 105px;
    text-align: center;
    position: relative;
    top: -5px;
}

.contact__text {
    display: block;
    margin-bottom: 10px;
}

.contact-50w:nth-of-type(2n) .contact__text-block {
    text-align: right;
}

.contact-50w:nth-of-type(2n) {
    justify-content: flex-end;
}

.contact__title {
    display: block;
    font-weight: 400;
    font-size: 24px;
    color: #292929;
    margin-bottom: 10px;
}

.contacts__title {
    text-align: center;
    font-size: 20px;
    font-weight: 400;
    text-decoration: underline;
    text-transform: uppercase;
}

.contact__text {
    font-size: 22px;
    color: #747373;
    font-weight: 400;
}

.contact__text_phones {
    white-space: nowrap;
}

.contacts__block {
    box-sizing: border-box;
    padding-left: 15px;
    padding-right: 15px;
}

.contacts-social {
    width: 37px;
    height: 37px;
    color: #fff;
    font-size: 22px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    margin-right: 7px;
}

.contacts-social_vk {
    background: #4d75a3;
}

.contacts-social_facebook {
    background: #3c5b9b;
}

.contacts-social_instagram {
    background: #715353;
}

.contacts-social_skype {
    background: #00aaf1;
}

.contacts-social_youtube {
    background: #fe3432;
}

.contacts-map {
    height: 500px;
    margin-top: 75px;
    box-shadow: 0px 35px 40px 20px;
}

.contacts-map-container {
    overflow: hidden;
}