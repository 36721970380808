.tooltip {
    position: absolute;
    z-index: 50;
    left: 50%;
    display: flex;
    visibility: hidden;
    max-width: 300px;
    opacity: 1;
    color: black;
    border-radius: 5px;
    background: white;
    box-shadow: 0 0 10px -2px black;
    font-size: 12px;
    font-weight: normal;
}

.tooltip_bottom {
    top: calc(100% + 10px);
    transform: translate(-50%, 0);
}

.tooltip__wrapper {
    position: relative;
    z-index: 1;
    display: flex;
    overflow: hidden;
    align-items: center;
    flex-direction: column;
    border-radius: 10px;
    background: white;
}

.tooltip__wrapper_padding {
    padding: 10px;
}

.tooltip__text-title {
    font-size: 1.4em;
}

.tooltip__text {
    min-width: 280px;
    margin-bottom: 10px;
}

.tooltip:before {
    position: absolute;
    z-index: 1;
    left: 50%;
    width: 15px;
    height: 15px;
    content: "";
    transform: translate(-50%, -50%) rotate(225deg);
    background: white;
    box-shadow: 0 0 10px -2px black;
}

.tooltip:after {
    position: absolute;
    z-index: -1;
    left: 0;
    width: 100%;
    height: 20px;
    content: "";
    transform: translateY(-100%);
}

.tooltip__images {
    display: grid;
    align-items: center;
    width: 100%;
    max-width: 300px;
    margin-bottom: 10px;

    grid-column-gap: 5px;
    grid-template-columns: repeat(3, 1fr);
}
.tooltip__img {
    display: block;
    width: 100%;
}

@media screen and (max-width: 599px) {
    .tooltip_hidden-mobile {
        display: none;
    }
}
