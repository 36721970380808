.mainPartners {
    display: flex;
}

.mainPartnersBlock {
    display: flex;
    flex-direction: column;
    flex: 1;
    padding-right: 15px;
    padding-left: 15px;
}

.mainPartnersBlock_img {
    width: 100%;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
}

.mainPartnersBlock_img:before {
    content: "";
    display: block;
    padding-top: 50%;
}

.mainPartnersBlock_img > img {
    width: 60%;
}

.mainPartnersBlock_textBlock {
    background-color: #ffffff;
    border: 2px solid rgb(215, 215, 215);
    border-radius: 10px;
    padding: 10px 15px 25px 15px;
    min-height: 160px;
}

.mainPartnersBlock_textBlock > h3 {
    color: rgb(255, 114, 0);
    text-align: center;
    font-size: 28px;
    margin-bottom: 10px;
    min-height: 60px;
    display: flex;
    align-items: flex-end;
    justify-content: center;
}

.mainPartnersBlock_textBlock > p {
    text-align: center;
}

@media screen and (max-width: 1169px) {


    .mainPartnersBlock_textBlock > h3 {
        font-size: 24px;
    }

    .mainPartnersBlock_textBlock > p {
        font-size: 15px;
    }
}