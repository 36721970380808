/*---------- Личный кабинет ------------*/

.page-personal {
    padding: 69px 113px;
    box-sizing: border-box;
}

.personal-tab__name {
    display: inline-block;
    font-size: 22px;
    text-transform: uppercase;
    padding-left: 0px;
    max-width: 0px;
    cursor: pointer;
    overflow: hidden;
    padding-left: 0;
}

.personal-tab__icon {
    font-size: 22px;
    width: 22px;
    height: 22px;
    text-align: center;
}

.personal-tab {
    display: flex;
    align-items: center;
    background-color: #ebe9e9;
    padding: 13px 13px;
    border-radius: 100px;
    overflow: hidden;
    cursor: pointer;
    margin-right: 15px;
    border: 1px solid #dedede;
    color: #aeadad;
}

.personal-tabs {
    display: flex;
    margin-bottom: 40px;
    white-space: nowrap;
}

.personal-tab-content {
    display: none;
}

.personal-tab-content_active {
    display: block;
    animation: personal-tab ease-in-out 0.5s;
}

@-webkit-keyframes personal-tab {
    0% {
        display: none;
        opacity: 0;
    }
    1% {
        display: block;
        opacity: 0;
    }

    100% {
        display: block;
        opacity: 1;
    }
}

@-moz-keyframes personal-tab {
    0% {
        display: none;
        opacity: 0;
    }
    1% {
        display: block;
        opacity: 0;
    }

    100% {
        display: block;
        opacity: 1;
    }
}

@-o-keyframes personal-tab {
    0% {
        display: none;
        opacity: 0;
    }
    1% {
        display: block;
        opacity: 0;
    }

    100% {
        display: block;
        opacity: 1;
    }
}

@keyframes personal-tab {
    0% {
        display: none;
        opacity: 0;
    }
    1% {
        display: block;
        opacity: 0;
    }

    100% {
        display: block;
        opacity: 1;
    }
}

.personal-tab:hover:not(.personal-tab_active) {
    border: 1px solid #ff7200;
}

.personal-tab_active {
    background-color: #ff7200;
    border: 1px solid transparent;
    color: #ffffff;
}

.personal-tab_active .personal-tab__name {
    max-width: 500px;
    padding-left: 15px;
}

.personal-tab__name,
.personal-tab {
    -webkit-transition: max-width .3s, border .3s, padding .5s, background .3s linear;
    -moz-transition: max-width .3s, border .3s, padding .5s, background .3s linear;
    -o-transition: max-width .3s, border .3s, padding .5s, background .3s linear;
    transition: max-width .3s, border .3s, padding .5s, background .3s linear;
}

.personal-order {
    margin-bottom: 25px;
    border-radius: 5px;
    overflow: hidden;
}

.personal-order__head {
    display: flex;
    justify-content: space-between;
    font-size: 24px;
    background: #dcdcdc;
    color: #747373;
    padding: 15px;
    cursor: pointer;
}

.personal-order__head:hover:not(.personal-order__head_active) {
    background: #e8e8e8;
}

.personal-order__head div,
.personal-order__head span,
.personal-order__head i {
    cursor: pointer;
}

.personal-order__head-left {
    display: flex;
}

.personal-order__head-right {
    display: flex;
    justify-content: flex-end;
}

.personal-order__head-amount {
    margin-right: 20px;
}

.personal-order__head-order {
    margin-left: 40px;
    width: 240px;
}

.personal-order__body {
    display: none;
    padding: 40px 25px 20px;
    box-sizing: border-box;
    border: 2px solid #dcdcdc;
    background: #FFFFFF;
}

.personal-order__body-title {
    display: block;
    width: 100%;
    font-weight: 300;
    font-size: 22px;
    color: #292929;
    text-transform: uppercase;
    text-align: center;
    margin-bottom: 30px;
}

.personal-order__info-table {
    font-weight: 300;
    font-size: 22px;
    color: #292929;
    margin-left: 15px;
}

.personal-order__info-tabe-ago {
    font-size: 20px;
    color: #aeadad;
}

.personal-order__info-table td {
    padding: 15px;
}

.personal-order__info-table-amount {
    font-weight: 400;
    color: #ff7200;
}

.personal-order__items-table {
    font-weight: 300;
    font-size: 22px;
    color: #292929;
    border-collapse: collapse;
    width: 100%;
    margin-bottom: 25px;
}

.personal-order__items-table thead {
    background: #ff7200;
    font-weight: 400;
    font-size: 18px;
    color: #ffffff;
    border: 1px solid #dcdcdc;
}

.personal-order__items-table th {
    padding: 15px;
}

.personal-order__items-table tr {
    border: 1px solid #dcdcdc;
}

.personal-order__items-table td {
    padding-top: 10px;
    padding-bottom: 10px;
    vertical-align: middle;
    text-align: center;
}

.personal-order__items-table tr td:nth-of-type(2) {
    text-align: left;
}

.personal-order__items-table tr:nth-of-type(2n) {
    background: #f6f4f4;
}

.personal-order__items-table tbody tr:last-of-type {
    background: #ffffff;
    border: none;
    color: #ff7200;
    font-weight: 400;
}

.personal-order__items-table td img {
    width: 50px;
}

.personal-order__head_active {
    color: #ffffff;
    background: #ff7200;
}

.personal-order__head_active:hover {
    background: #ff9946;
}

.personal-order__head i {
    color: #ffffff;
}

.personal-order__head_active i {
    transform: rotate(180deg);
}

.personal-order__item-link {
    color: #292929;
}

.personal-order__item-link:hover {
    color: #ff7200;
}

.personal-orders-empty {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.personal-orders-empty__title {
    font-size: 50px;
    color: #dcdcdc;
    margin-top: 25px;
}

.personal-orders-empty__icon {
    font-size: 200px;
    color: #dcdcdc;
    margin-bottom: 25px;
}

.personal-profile {
    padding: 45px 100px;
}

.personal-main-content-title {
    font-weight: 300;
    font-size: 22px;
    color: #292929;
    text-transform: uppercase;
    text-align: center;
    margin-bottom: 45px;
}

.personal-btn__link_orders-empty {
    margin-bottom: 50px;
}

.personal-profile-form {
    display: flex;
    justify-content: space-between;
}

.personal-profile-form_left,
.personal-profile-form_right {
    width: 40%;
}

.personal-profile-form__input {
    width: 100%;
}

.personal-profile-form__label {
    display: flex;
    justify-content: space-between;
    font-weight: 300;
    font-size: 22px;
    color: #292929;
    margin-bottom: 10px;
}

.personal-profile-form__input-container {
    display: flex;
    flex-direction: column-reverse;
}

.personal-profile-form__label .fa-pencil {
    color: #dcdcdc;
}

.personal-profile-form__label span {
    margin-left: 15px;
}

.personal-profile-form__input:focus + .personal-profile-form__label .fa-pencil {
    color: #ff7200;
}

.personal-profile-form__input {
    width: 100%;
    box-sizing: border-box;
    background: #f6f4f4;
    padding: 13px 35px 13px 15px;
    font-weight: 300;
    font-size: 22px;
    margin-bottom: 25px;
    background-repeat: no-repeat;
    background-position: 98%;
    background-size: 25px;
    border: 1px solid #dcdcdc;
}

.personal-profile-form__input::placeholder {
    color: #888787;
}

.personal-profile-form__input:not(:placeholder-shown):valid {
    border: 1px solid green;
    color: green;
    background-image: url(../../img/images/purchase/checked.svg)
}

.personal-profile-form__input:not(:placeholder-shown):invalid {
    border: 1px solid red;
    color: red;
    background-image: url(../../img/images/purchase/cancel.svg)
}

.personal-profile-form__input:focus {
    color: #ff7200;
    background: #ffffff;
    border: 1px solid #ff7200;
    background-position: 120%;
}

.personal-profile-form__input:focus:not(:placeholder-shown):valid,
.personal-profile-form__input:focus:not(:placeholder-shown):invalid {
    color: #ff7200;
    background: #ffffff;
    border: 1px solid #ff7200;
    background-position: 120%;
}

.personal-btn_profile {
    margin-top: 25px;
    cursor: pointer;
    border: none;
}

.personal-status {
    padding: 45px 50px;
}

.personal-status-txt {
    display: flex;
}

.personal-status-txt__icon {
    color: #ff7200;
    font-size: 40px;
    margin-right: 30px;
    width: 145px;
    text-align: center;
}

.personal-status-txt__text {
    font-weight: 300;
    font-size: 22px;
    color: #292929;
    line-height: 1.2;
}

.personal-status__status-title {
    display: block;
    font-weight: 300;
    font-size: 22px;
    color: #292929;
    text-align: center;
    margin-top: 50px;
    margin-bottom: 15px;
}

.personal-status__status-name {
    display: block;
    font-size: 22px;
    color: #ff7200;
    text-transform: uppercase;
    text-align: center;
    margin-bottom: 45px;
}

.personal-status__status-partner {
    display: block;
    font-weight: 300;
    font-size: 22px;
    color: #292929;
    text-align: center;
    margin-bottom: 50px;
}

.personal-status__status-link {
    display: inline-block;
    color: #ff7200;
    line-height: 1;
    border-bottom: 1px solid #ff9946;
}

.personal-status-txt__range {
    display: flex;
    margin-bottom: 25px;
}

.personal-status-txt__range-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50px;
    height: 50px;
    border: 1px solid #ff7200;
    border-radius: 50%;
    font-weight: 400;
    font-size: 22px;
    color: #ff7200;
    margin-right: 25px;
}

.personal-status-txt__range-text {
    display: flex;
    align-items: center;
}

.personal-status-txt__range-block {
    margin-top: 25px;
    margin-bottom: 30px;
}

.personal-btn {
    text-align: center;
}

.personal-btn__link {
    background: #ff7200;
    display: inline-block;
    color: #ffffff;
    padding: 15px 25px;
    text-transform: uppercase;
    font-weight: 300;
    font-size: 22px;
    border-radius: 150px;
    border: none;
    cursor: pointer;
}

.personal-btn__link i {
    margin-right: 10px;
}

.personal-btn__link:hover {
    background: #ff9946;
}

.personal-problem {
    padding: 45px 50px 40px;
}

.personal-problem-p {
    font-weight: 300;
    font-size: 22px;
    color: #292929;
    text-align: center;
    margin-bottom: 40px;
    line-height: 1.3;
}

.personal-problem-p a {
    display: inline-block;
    line-height: 1;
    border-bottom: 1px solid #ff7200;
}

.personal-problem-p a:hover {
    color: #ff9946;
    border-bottom: 1px solid #ff9946;
}

.personal-btn_status {
    margin-bottom: 40px
}

.personal-box {
    background: #ffffff;
    border: 2px solid #dcdcdc;
    border-radius: 7px;
    box-sizing: border-box;
}

.personal-exit {
    padding-top: 45px;
}

@media screen and (max-width: 1169px) {
    .page-personal {
        padding: 50px;
    }
}

@media screen and (max-width: 959px) {

    .personal-order__head {
        font-size: 18px;
    }

    .personal-tab__name {
        font-size: 18px;
    }

    .personal-orders-empty__title {
        font-size: 35px;
    }

    .personal-orders-empty__icon {
        font-size: 150px;
    }

    .personal-btn__link {
        font-size: 18px;
    }

    .personal-order__head-order {
        margin-left: 20px;
        width: 190px;
    }

    .personal-order__body-title {
        font-size: 18px;
    }

    .personal-order__info-table {
        font-size: 16px;
        margin-left: 0;
    }

    .personal-order__items-table {
        font-size: 18px;
    }

    .personal-order__items-table thead {
        font-size: 16px;
    }

    .personal-main-content-title {
        font-size: 18px;
    }

    .personal-profile {
        padding: 35px 60px;
    }

    .personal-profile-form__input {
        font-size: 18px;
    }

    .personal-profile-form__label {
        font-size: 18px;
    }

    .personal-profile-form_left,
    .personal-profile-form_right {
        width: 45%;
    }

    .personal-status-txt__text {
        font-size: 18px;
    }

    .personal-status-txt__icon {
        font-size: 30px;
    }

    .personal-status__status-title {
        font-size: 18px;
        margin-top: 30px;
    }

    .personal-status__status-name {
        font-size: 20px;
        margin-bottom: 30px;
    }

    .personal-status__status-partner {
        font-size: 18px;
        margin-bottom: 30px;
    }

    .personal-status-txt__range-icon {
        width: 40px;
        height: 40px;
        font-size: 18px;
    }

    .personal-problem-p {
        font-size: 18px;
        margin-bottom: 25px;
    }
}

@media screen and (max-width: 767px) {

    .personal-orders-empty__title {
        font-size: 28px;
        margin-top: 10px;
    }

    .personal-orders-empty__icon {
        font-size: 100px;
    }

    .personal-tab__icon {
        font-size: 13px;
        width: 14px;
        height: 14px;
    }

    .personal-tab__name {
        font-size: 13px;
    }

    .personal-tab_active .personal-tab__name {
        padding-left: 10px;
    }

    .personal-tabs {
        margin-bottom: 20px;
    }

    .personal-tab {
        padding: 10px 10px;
        margin-right: 10px;
    }

    .page-personal {
        padding: 35px 15px;
    }

    .personal-order {
        margin-bottom: 15px;
    }

    .personal-order__head {
        font-size: 14px;
    }

    .personal-order__head-order {
        margin-left: 0;
        width: 85px
    }

    .personal-order__head-order span {
        display: none;
    }

    .personal-order__head-amount span {
        display: none;
    }

    .personal-order__body {
        padding: 15px 0px 15px;
    }

    .personal-order__body-title {
        font-size: 16px;
    }

    .personal-btn__link {
        font-size: 14px;
    }

    .personal-order__info-table {
        font-size: 14px;
        line-height: 1.2;
        margin-bottom: 15px;
    }

    .personal-order__info-table tr td:nth-of-type(1) {
        white-space: nowrap;
    }

    .personal-order__info-table td {
        padding: 10px 5px 10px 10px;
    }

    .personal-order__info-tabe-ago {
        white-space: nowrap;
    }

    .personal-order__body-title {
        margin-bottom: 15px;
    }

    .personal-order__info-tabe-ago {
        font-size: 14px;
    }

    .personal-order__items-table thead {
        font-size: 14px;
    }

    .personal-order__items-table {
        font-size: 14px;
    }

    .personal-order__items-table tr > *:nth-child(1) {
        display: none;
    }

    .personal-order__items-table tr td:nth-of-type(2) {
        padding: 10px
    }

    .personal-order__items-table th {
        white-space: nowrap;
        padding: 10px;
    }

    .personal-profile {
        padding: 20px 15px;
    }

    .personal-main-content-title {
        font-size: 16px;
        margin-bottom: 20px;
    }

    .personal-profile-form {
        flex-direction: column;
    }

    .personal-profile-form_left,
    .personal-profile-form_right {
        width: 100%;
    }

    .personal-profile-form__input {
        font-size: 16px;
        padding: 10px 35px 10px 15px;
        margin-bottom: 15px;
    }

    .personal-profile-form__label {
        font-size: 16px;
        margin-bottom: 5px;
    }

    .personal-status {
        padding: 20px 15px;
    }

    .personal-status-txt__icon {
        display: none;
    }

    .personal-status-txt__text {
        font-size: 14px;
        text-align: center;
    }

    .personal-status__status-title {
        font-size: 16px;
        margin-top: 20px;
        margin-bottom: 10px;
    }

    .personal-status__status-name {
        font-size: 18px;
        margin-bottom: 20px;
    }

    .personal-status__status-partner {
        font-size: 16px;
        margin-bottom: 20px;
    }

    .personal-status-txt__range {
        margin-bottom: 10px;
    }

    .personal-problem {
        padding: 20px 15px;
    }

    .personal-problem-p {
        font-size: 14px;
        margin-bottom: 15px;
    }

    .personal-btn_status {
        margin-bottom: 20px;
    }

    .personal-exit {
        padding: 20px 20px 0px;
    }

    .personal-btn__link_orders-empty {
        margin-bottom: 0px;
    }

    .headTitle h1 {
        font-size: 26px;
    }

}

@media screen and (max-width: 320px) {

    .personal-orders-empty__title {
        font-size: 24px;
        margin-top: 0px;
    }

    .personal-orders-empty__icon {
        font-size: 75px;
    }

    .personal-tab__icon {
        font-size: 12px;
        width: 12px;
        height: 12px;
    }

    .personal-tab__name {
        font-size: 12px;
    }

    .personal-tab_active .personal-tab__name {
        padding-left: 5px;
    }

    .personal-tab {
        padding: 7px 7px;
        margin-right: 7px;
    }

    .page-personal {
        padding: 25px 15px;
    }

    .personal-order__head {
        font-size: 14px;
        padding: 10px
    }

    .personal-order__head-order {
        margin-left: 0;
        width: 80px
    }

    .personal-order__body-title {
        font-size: 14px;
    }

    .personal-order__head-amount {
        margin-right: 5px
    }

    .personal-main-content-title {
        font-size: 14px;
        margin-bottom: 15px;
    }

    .personal-profile-form__input {
        font-size: 14px;
        margin-bottom: 10px;
    }

    .personal-profile-form__label {
        font-size: 14px;
    }

    .personal-btn_profile {
        margin-top: 10px;
    }

    .personal-status__status-name {
        font-size: 16px;
        margin-bottom: 15px;
    }

    .personal-status-txt__range-icon {
        margin-right: 10px;
        width: 30px;
        height: 30px;
        font-size: 14px;
    }
}

/* --------- Новый пароль -------- */

.page-new-password {
    padding: 75px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.new-password-title {
    font-size: 25px;
    font-weight: 300;
    text-transform: uppercase;
    margin-bottom: 25px;
}

.new-password {
    display: flex;
    flex-direction: column;
    max-width: 320px;
}

.new-password__input {
    background-color: #ffffff;
    font-size: 22px;
    border: 1px solid #dcdcdc;
    margin-bottom: 15px;
    font-family: Arial;
    padding: 7px 35px 7px 10px;
    background-repeat: no-repeat;
    background-position: 120%;
    background-size: 25px;
}

.new-password__submit {
    background: #ff7200;
    border: none;
    border-radius: 7px;
    padding: 10px;
    text-transform: uppercase;
    color: #ffffff;
    font-size: 18px;
    cursor: pointer;
    font-weight: 300;
}

.new-password__submit:hover {
    background: #ff9946;
}

.new-password__input:not(:placeholder-shown):valid {
    color: green;
    border-color: green;
    /* clean-css ignore:start */
    background-image: url(../img/images/purchase/checked.svg);
    /* clean-css ignore:end */
    background-position: 98%;
}

.new-password__input:not(:placeholder-shown):invalid {
    color: red;
    border-color: red;
    /* clean-css ignore:start */
    background-image: url(../img/images/purchase/cancel.svg);
    /* clean-css ignore:end */
    background-position: 98%;
}

.new-password__note {
    color: #747373;
    font-weight: 300;
    font-size: 16px;
    margin-bottom: 15px;
    text-align: center;
    max-height: 25px;
}

@media screen and (max-width: 375px) {
    .new-password-title {
        font-size: 21px;
    }

    .page-new-password {
        padding: 40px 0;
    }
}