/*---------  О компании ---------*/

.page-company {
    padding: 50px 15px 20px 15px;
    box-sizing: border-box;
}

.about-company {
    display: flex;
    margin-bottom: 65px;
}

.slider-company-slider-block {
    width: 35%;
    padding-top: 35px;
}

.slider-company__slider {
    display: flex;
    text-align: center;
}

.slider-company__slide-icon {
    font-size: 125px;
    color: #d8d8d8;
    margin-bottom: 40px;
}

.slider-company__slide-str-first {
    display: block;
    font-weight: 800;
    font-size: 90px;
    white-space: nowrap;
    color: #ff7200;
}

.slider-company__slide-str-second {
    display: block;
    font-weight: 800;
    font-size: 54px;
    color: #747373;
}

.company-story {
    width: 65%;
    border: 2px solid #dcdcdc;
    border-radius: 7px;
    box-sizing: border-box;
    padding: 35px 45px;
    text-align: center;
}

.company-story h2 {
    display: inline-block;
    width: 60%;
    font-weight: 300;
    font-size: 30px;
    text-transform: uppercase;
    border-bottom: 2px solid #ff7200;
    text-align: center;
    padding-bottom: 5px;
    margin-bottom: 25px;
}

.company-story p {
    font-weight: 300;
    font-size: 26px;
    color: #292929;
    text-align: center;
    margin-bottom: 25px;
}

.company-story p:last-of-type {
    margin-bottom: 0;
}

.company-title {
    display: block;
    text-align: center;
    text-transform: uppercase;
    font-weight: 300;
    font-size: 30px;
    color: #292929;
}

.mar-bm-50 {
    margin-bottom: 50px;
}

.mar-bm-45 {
    margin-bottom: 45px;
}

.associate {
    display: flex;
}

.associate__image-and-name {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 30%;
}

.associate__bio {
    width: 70%;
}

.associate__img {
    height: 210px;
    width: 210px;
    object-fit: cover;
    border-radius: 50%;
    border: 3px solid #dcdcdc;
    margin-bottom: 30px;
}

.associate__name {
    font-size: 22px;
    text-transform: uppercase;
    text-align: center;
    color: #ff7200;
    margin-bottom: 15px;
    line-height: 1.3;
    white-space: nowrap;
}

.associate__position {
    font-weight: 300;
    font-size: 22px;
    color: #292929;
    text-align: center;
    line-height: 1.3;
}

.associate__bio {
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    padding-left: 60px;
}

.associate__bio-date {
    align-self: flex-end;
    font-weight: 300;
    font-size: 24px;
    color: #ff7200;
    margin-bottom: 50px;
}

.associate {
    border-top: 2px solid #ff7200;
    padding: 35px 75px;
    box-sizing: border-box;
}

.associate__bio > p {
    font-weight: 300;
    font-size: 24px;
    color: #aeadad;
    line-height: 1.2;
    margin-bottom: 25px;
}

.associate__bio > p:last-of-type {
    margin-bottom: 0;
}

@media screen and (min-width: 768px) {
    .associate:nth-of-type(2n) .associate__image-and-name {
        order: 1;
    }

    .associate:nth-of-type(2n) .associate__bio-date {
        align-self: flex-start;
    }

    .associate:nth-of-type(2n) .associate__bio {
        padding-left: 0;
        padding-right: 60px;
    }
}

.employees-hide-block {
    display: none;
}

/*- Кнопка width 100% -*/

.broad-btn {
    display: block;
    background: #ff7200;
    border-radius: 7px;
    padding-top: 25px;
    padding-bottom: 20px;
    font-size: 30px;
    color: #ffffff;
    text-align: center;
    text-transform: uppercase;
    cursor: pointer;
}

.broad-btn:hover {
    background: #ff9946;
}

.company-video {
    width: 68%;
    margin: 0 auto;
    margin-bottom: 75px;
}

.company-video__wrapper {
    position: relative;
    padding-bottom: 56.25%;
    /*пропорции видео 16:9 */
    padding-top: 25px;
    height: 0;
}

.company-video iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.slider_4-slides-fancybox {
    margin-left: -15px;
    margin-right: -15px;
}

.slider__slide_4-slides-fancybox {
    padding: 0 15px;
    box-sizing: border-box;
}

.slider__fancybox-link {
    display: block;
    border: 2px solid #dcdcdc;
    box-sizing: border-box;
}

.slider__img {
    width: 100%;
}

.slider_4-slides-fancybox .slick-list {
    margin-bottom: 30px;
}

.slider_4-slides-fancybox .slick-dots {
    position: static;
}

@media screen and (max-width: 1169px) {
    .about-company {
        margin-bottom: 45px;
    }

    .company-story h2 {
        font-size: 25px;
    }

    .company-story p {
        font-size: 21px;
    }

    .slider-company__slide-icon {
        font-size: 100px
    }

    .slider-company__slide-str-first {
        font-size: 75px;
    }

    .slider-company__slide-str-second {
        font-size: 44px;
    }

    .company-title {
        font-size: 25px;
    }

    .mar-bm-45 {
        margin-bottom: 35px;
    }

    .associate__img {
        width: 180px;
        height: 180px;
    }

    .associate__name {
        font-size: 18px;
    }

    .associate__position {
        font-size: 18px;
    }

    .associate__bio-date {
        font-size: 21px;
    }

    .associate__bio > p {
        font-size: 21px;
        margin-bottom: 20px;
    }

    .broad-btn {
        font-size: 27px;
        padding-top: 22px;
        padding-bottom: 17px;
    }

    .mar-bm-50 {
        margin-bottom: 40px;
    }

}

@media screen and (max-width: 959px) {
    .about-company {
        margin-bottom: 35px;
    }

    .company-story {
        padding: 25px;
    }

    .company-story h2 {
        font-size: 21px;
    }

    .company-story p {
        font-size: 18px;
    }

    .slider-company__slide-icon {
        font-size: 80px
    }

    .slider-company__slide-str-first {
        font-size: 60px;
        margin-top: 20px;
    }

    .slider-company__slide-str-second {
        font-size: 35px;
        margin-top: -15px;
        margin-bottom: 15px;
    }

    .slider-company__slider .slick-dots li button::before {
        font-size: 16px;
        width: 16px;
        height: 16px;
    }

    .company-title {
        font-size: 22px;
    }

    .mar-bm-45 {
        margin-bottom: 30px;
    }

    .associate {
        padding: 25px 25px;
    }

    .associate__img {
        width: 130px;
        height: 130px;
    }

    .associate__name {
        font-size: 16px;
    }

    .associate__position {
        font-size: 16px;
    }

    .associate__bio-date {
        font-size: 18px;
        margin-bottom: 15px;
    }

    .associate__bio > p {
        font-size: 18px;
        margin-bottom: 15px;
    }

    .broad-btn {
        font-size: 24px;
        padding-top: 19px;
        padding-bottom: 14px;
    }
}

@media screen and (max-width: 767px) {
    .page-company {
        padding-top: 30px;
    }

    .about-company {
        flex-direction: column;
    }

    .slider-company__slide-str-second {
        margin-bottom: 0px;
    }

    .slider-company-slider-block {
        width: 100%;
        margin-bottom: 25px;
        padding-top: 0;
    }

    .slider-company__slide-icon {
        margin-bottom: 0;
    }

    .company-story {
        width: 100%;
        padding: 20px 10px;
    }

    .company-story h2 {
        font-size: 18px;
        width: 90%;
    }

    .company-story p {
        font-size: 14px;
    }

    .associate {
        flex-direction: column;
        padding: 25px 15px;
    }

    .associate__image-and-name {
        width: 100%;
        margin-bottom: 25px;
    }

    .associate__bio {
        width: 100%;
        padding: 0;
    }

    .broad-btn {
        font-size: 16px;
    }

    .company-video {
        width: 100%;
        margin-bottom: 25px;
    }

    .company-title {
        font-size: 16px;
    }
}