/* Сброс стандартных стилей браузеров */

html,
body,
div,
span,
applet,
object,
iframe,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
del,
dfn,
em,
font,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend {
    vertical-align: baseline;
    font-family: inherit;
    font-weight: inherit;
    font-style: inherit;
    font-size: 100%;
    outline: 0;
    padding: 0;
    margin: 0;
    border: 0;
}

:focus {
    outline: 0;
}

:hover {
    text-decoration: none;
}

input:not([type=checkbox]):not([type=radio]),
textarea,
select {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}

html {
    /* Cглаживание шрифтов на MAC OSX - ВНИМАНИЕ!!! Делают шрифт немного тоньше!!! */
    -moz-osx-font-smoothing: grayscale !important;
    -webkit-font-smoothing: antialiased !important;
}

a {
    color: rgb(255, 114, 0);
    text-decoration: none;
}

a:hover {
    text-decoration: none;
}

p {
    color: #292929;
    /*Темно серый*/
}

*,
::after,
::before {
    box-sizing: initial;
}