.mainReviewsBlock_marginTop {
    margin-top: 30px;
}

.mainReviewsBlockContainer {
    display: flex;
    flex-wrap: wrap;
    margin-top: 50px;
}

.mainReviewsWrapper {
    width: 25%;
}

.mainReviewsBlock {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.mainReviewsBlock__img {
    display: flex;
    justify-content: center;
}

.mainReviewsBlock__img > img {
    width: 100%;
    border-radius: 50%;
    border: 2px solid rgb(255, 114, 0);
}

.mainReviewsBlock__name {
    display: block;
    text-align: center;
    font-size: 20px;
    margin-top: 15px;
    min-height: 36px;
}

.mainReviewsBlock__level {
    margin-top: 5px;
}

.mainReviewsBlock__level > .fa-star {
    color: #dcdcdc;
    font-size: 20px;
}

.mainReviewsBlock__level > .active {
    color: rgb(255, 114, 0);
}

.mainReviewsBlock__text {
    line-height: 25px;
    width: 70%;
    height: 103px;
    text-align: center;
    font-size: 16px;
    margin-top: 10px;
}

.mainReviewsBlock__btn {
    display: block;
    margin-top: 15px;
    color: rgb(255, 114, 0);
    border-bottom: 1px solid rgb(255, 114, 0);
}

.mainReviewsBlock__text {
    line-height: 25px;
    width: 70%;
    height: 105px;
    text-align: center;
    font-size: 16px;
    margin-top: 10px;
    overflow: hidden;
}

@media screen and (max-width: 1169px) {

    .mainReviewsBlock__name {
        font-size: 18px;
    }

    .mainReviewsBlock__text {
        font-size: 15px;
    }
}