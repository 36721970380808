.mainHaveQuestions {
    display: flex;
    margin-top: 30px;
}

.mainHaveQuestions__leftBlock {
    width: 50%;
}

.mainHaveQuestions__rightBlock {
    width: 50%;
    position: relative;
}

.mainHaveQuestions__rightBlock > .feedbackForm {
    position: absolute;
    z-index: 1;
}

@media screen and (max-width: 959px) {

    .mainHaveQuestions__rightBlock {
        display: none;
    }

    .mainHaveQuestions__leftBlock {
        width: 100%;
    }

}