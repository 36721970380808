
#map {
    width: 100%;
    height: 400px;
    box-shadow: 0px 51px 57px 32px;
}

.footerBlock {
    background-color: rgb(41, 41, 41);
    position: relative;
    display: flex;
    padding: 35px;
    font-size: 22px;
    width: 100%;
    box-sizing: border-box;
}

.footerBlockCenter {
    display: flex;
}

.footerBlockCenter__menu > ul,
.footerBlockCenter__category > ul {
    list-style: none;
}

.footerBlockCenter__category > ul {
    display: flex;
    flex-wrap: wrap;

}

.footerBlockCenter__category > ul > li {
    display: block;
    width: 50%;
    margin-bottom: 25px;
    padding-right: 5px;
    box-sizing: border-box;
}

.footerBlockCenter__category > ul > li > a {
    display: inline-block;
    color: #ffffff;
    font-weight: 300;
    border-bottom: 1px solid transparent;
}

.footerBlockCenter__category > ul > li > a:hover {
    color: rgb(255, 114, 0);
    border-bottom: 1px solid rgb(255, 114, 0);
}

.footerBlockLeft,
.footerBlockRight {
    width: 25%;
}

.footerBlockCenter {
    width: 50%;
}

.footerBlockCenter__menu > ul > li {
    display: block;
    margin-bottom: 25px;
    padding-right: 10px;
    box-sizing: border-box;
}

.footerBlockCenter__menu > ul > li > a {
    display: inline-block;
    color: #ffffff;
    font-weight: 300;
    border-bottom: 1px solid transparent;
}

.footerBlockCenter__menu > ul > li > a:hover {
    color: rgb(255, 114, 0);
    border-bottom: 1px solid rgb(255, 114, 0);
}

.footerBlockCenter__menu > ul > li:first-of-type > a {
    color: rgb(255, 114, 0);
    border-bottom: 1px solid rgb(255, 114, 0);
}

.footerBlockCenter__menu > ul > li:first-of-type > a:hover {
    color: #ffffff;
    border-bottom: 1px solid #ffffff;
}

.footerBlockCenter__menu {
    width: 33.6%;
}

.footerBlockCenter__category {
    width: 66.6%;
}

.footerBlockLeft__phoneBlock {
    margin-bottom: 20px;
}

.footerBlockLeft__phone:hover {
    color: #ffffff;
}

.footerBlockLeft__phone {
    display: block;
    margin-bottom: 5px;
}

.footerBlockLeft__copyrightText,
.footerBlockLeft__work {
    display: block;
    color: #747373;
    margin-bottom: 20px;
    line-height: 1.3;
}

.footerBlockLeft__adress {
    display: block;
    color: #747373;
    margin-bottom: 35px;
    line-height: 1.3;
}

.footerBlockLeft__workName,
.footerBlockLeft__workTime,
.footerBlockLeft__workNote {
    display: block;
}

.footerBlockLeft__workName {
    color: #ffffff;
}

.footerBlockLeft__workNote {
    font-size: 18px;
}

.footerBlockRight__social {
    display: flex;
    justify-content: flex-end;
    font-size: 40px;
}

.footerBlockRight__social > a {
    min-width: 60px;
    min-height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    margin-left: 15px;
    margin-bottom: 15px;
    color: #ffffff;
}

.footerBlockRight__vk {
    background-color: #4d75a3;
}

.footerBlockRight__facebook {
    background-color: #3c5b9b;
}

.footerBlockRight__instagram {
    background-color: #715353;
}

.footerBlockRight__skype {
    background-color: #00aaf1;
}

.footerBlockRight__youtube {
    background-color: #fe3432;
}

.footerBlockRight__email {
    background-color: #ff7200;
}

.footerBlockRight__vk:hover {
    background-color: #1f64b1;
}

.footerBlockRight__facebook:hover {
    background-color: #1846a2;
}

.footerBlockRight__instagram:hover {
    background-color: #563c3c;
}

.footerBlockRight__skype:hover {
    background-color: #008ec9;
}

.footerBlockRight__youtube:hover {
    background-color: #d60200;
}

.footerBlockRight__email:hover {
    background-color: #db6200;
}

.footerBlockRight__pay {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    margin-top: 35px;
}

.footerBlockRight__pay > span,
.footerBlockRight__pay > a {
    font-weight: 300;
    color: #ffffff;
    margin: 20px 0 20px;
}

.footerBlockRight__pay > a:hover {
    color: rgb(255, 114, 0);
}

.footerBlockRight__pay > img {
    width: 75%;
}

@media screen and (max-width: 1450px) {
    .footerBlockRight__social {
        font-size: 30px;
    }

    .footerBlock {
        font-size: 18px;
    }

    .footerBlockRight__social > a {
        min-width: 40px;
        min-height: 40px;
    }

}

@media screen and (max-width: 1250px) {
    .footerBlockRight__social {
        font-size: 25px;
    }
}

@media screen and (max-width: 1169px) {

    .footerBlockRight__social {
        flex-wrap: wrap;
        margin-bottom: -15px;
    }

    .footerBlock {
        font-size: 16px;
    }

    .footerBlockRight__pay > a {
        font-size: 14px;
    }

    .footerBlockRight {
        display: none;
    }

    .footerBlockCenter {
        width: 75%;
    }
}


@media screen and (max-width: 767px) {
    .footer {
        margin-top: 40%;
    }

    .footerBlockCenter {
        display: none;
    }

    .footerBlock {
        flex-direction: column;
    }

    .footerBlockLeft {
        width: 100%;
        text-align: center;
    }

    .footerBlockRight {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .footerBlockRight__pay {
        align-items: center;
    }

    .footerBlockRight__pay > img,
    .footerBlockRight__pay > span {
        display: none;
        text-align: center;
    }

    .footerBlockRight__social {
        width: 320px;
        flex-wrap: nowrap;

    }

    .footerBlockRight__social > a {
        margin-left: 5px;
        margin-right: 5px;
        margin-bottom: 0;
    }

    .footerBlockLeft__adress > br {
        display: none;
    }

    .footerBlockLeft__adress {
        margin-bottom: 0;
    }

    .footerBlockLeft__workName {
        display: none;
    }

    .footerBlockLeft__workTime > br {
        display: none;
    }

    .footerBlockLeft__workTime {
        display: inline;
    }

    .footerBlockLeft__workNote {
        display: inline;
    }

    .footerBlock {
        font-size: 18px;
        padding: 15px;
    }

    .footerBlockRight__social {
        justify-content: center;
    }

}
