.mainNewsContainer {
    display: flex;
    margin-top: 25px;
    margin-bottom: 25px;
}

.mainNewsBlock {
    width: calc(33.3% - 30px);
    margin-left: 15px;
    margin-right: 15px;
    position: relative;
    overflow: hidden;
}

.mainNewsBlock__img {
    display: block;
    width: 100%;
}

.mainNewsBlock__textWrapper {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-color: rgba(0, 0, 0, 0.4);
}

.mainNewsBlock__title {
    height: 40%;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    padding-left: 10px;
    padding-right: 10px;
    font-weight: 700;
    font-size: 22px;
    color: rgb(255, 255, 255);
    text-transform: uppercase;
    text-align: center;
}

.mainNewsBlock__text {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 35%;
    padding-left: 10px;
    padding-right: 10px;
    color: #ffffff;
    text-align: center;
    font-size: 18px;
    font-weight: 300;
    line-height: 1.5;
    overflow: hidden;
    cursor: pointer;
}

.mainNewsBlock__btn {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 25%;
    font-size: 18px;
    text-decoration: underline;
}

.mainNewsBlock:hover .mainNewsBlock__img {
    filter: blur(2px);
}

.mainNewsBlock:hover .mainNewsBlock__textWrapper {
    background-color: rgba(0, 0, 0, 0.5) !important;
}

.mainNewsBlock__btn:hover {
    color: #ffffff;
}

@media screen and (max-width: 1169px) {

    .mainNewsBlock__title {
        font-size: 20px;
    }

    .mainNewsBlock__text {
        font-size: 16px;
    }

    .mainNewsBlock__btn {
        font-size: 16px;
    }
}

@media screen and (min-width: 768px) and (max-width: 959px) {
    .mainNewsBlock__title {
        font-size: 16px;
    }

    .mainNewsBlock__text {
        font-size: 14px;
        line-height: 1;
    }
}

@media screen and (max-width: 600px) {
    .mainNewsBlock__title {
        font-size: 16px;
        height: 30%;
    }

    .mainNewsBlock__text {
        font-size: 16px;
        line-height: 1;
        height: 50%;
    }

    .mainNewsBlock__btn {
        height: 20%;
    }

}