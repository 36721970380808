.mainConsultsBlock {
    display: flex;
    padding-top: 25px;
}

.mainConsultsBlock__left,
.mainConsultsBlock__right {
    width: 50%;
}

@media screen and (max-width: 959px) {

    .mainConsultsBlock__right {
        display: none;
    }

    .mainConsultsBlock__left {
        width: 100%;
    }
}

@media screen and (max-width: 767px) {

    .mainConsultsMarginTop {
        margin-top: 90px;
    }
}

