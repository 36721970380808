.mainContactsWrapper {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 40px;
}

.mainContactsBlock__title {
    width: 100%;
    text-align: center;
    margin-bottom: 25px;
    font-size: 20px;
    text-decoration: underline;
}

.mainContactsBlock {
    width: 100%;
    padding-left: 15px;
    padding-right: 15px;
    box-sizing: border-box;
}

.mainContacts {
    display: flex;
    flex-direction: column;
}

.mainContacts__contact {
    display: flex;
    margin-bottom: 35px;
}

.mainContacts__textBlock {
    display: flex;
    flex-direction: column;
}

.mainContacts__icon {
    min-width: 60px;
    font-size: 38px;
    color: rgb(255, 114, 0);
    text-align: center;
}

.mainContacts__firstString {
    display: flex;
    justify-content: space-between;
}

.mainContacts__secondString {
    display: flex;
    flex-direction: column;
}

.mainContacts__textBlock {
    font-size: 22px;
}

.mainContacts__text {
    color: #747373;
    margin-top: 10px;
}

.mainContacts__note {
    color: #747373;
}

.mainContacts__contact a.mainContacts__text:hover {
    color: rgb(255, 114, 0);
}

.mainConsultsMarginTop {
    margin-top: 50px;
}


@media screen and (max-width: 1169px) {

    .mainContacts__textBlock {
        font-size: 17px;
    }

}

@media screen and (max-width: 959px) {

    .mainContactsBlock {
        width: 90%;
    }

    .mainContacts__secondString {
        flex-direction: row;
    }

    .mainContacts__contact {
        width: 50%;
    }

}

@media screen and (max-width: 660px) {

    .mainContacts__contact {
        width: 100%;
    }

    .mainContacts__secondString,
    .mainContacts__firstString {
        flex-direction: column;
    }
}