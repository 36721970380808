/* Circe */

@font-face {
    font-family: "Circe";
    font-weight: 200;
    font-style: normal;
    src: url(../fonts/CRC25.eot), url(../fonts/CRC25.otf) format("opentype");
}

@font-face {
    font-family: "Circe";
    font-weight: 300;
    font-style: normal;
    src: url(../fonts/CRC35.eot), url(../fonts/CRC35.otf) format("opentype");
}

@font-face {
    font-family: "Circe";
    font-weight: 400;
    font-style: normal;
    src: url(../fonts/CRC55.eot), url(../fonts/CRC55.otf) format("opentype");
}

@font-face {
    font-family: "Circe";
    font-weight: 700;
    font-style: normal;
    src: url(../fonts/Circe-Bold.eot), url(../fonts/Circe-Bold.otf) format("opentype");
}

@font-face {
    font-family: "Circe";
    font-weight: 800;
    font-style: normal;
    src: url(../fonts/Circe-ExtraBold.eot), url(../fonts/Circe-ExtraBold.otf) format("opentype");
}

/* Proxima Nova */

@font-face {
    font-family: "Proxima Nova";
    font-weight: 300;
    font-style: normal;
    src: url(../fonts/Proxima-Nova-Light.otf) format("opentype");
}

@font-face {
    font-family: "Proxima Nova";
    font-weight: 400;
    font-style: normal;
    src: url(../fonts/Proxima-Nova-Regular.otf) format("opentype");
}

@font-face {
    font-family: "Proxima Nova";
    font-weight: 700;
    font-style: normal;
    src: url(../fonts/Proxima-Nova-Bold.otf) format("opentype");
}