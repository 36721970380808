.mainQuestionBlock > div {
    display: flex;
    margin-bottom: 25px;
}

.mainQuestionBlock_Number {
    width: 15%;
}

.mainQuestionBlock_Number > div {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 35px;
    height: 35px;
    background-color: #ffffff;
    border-radius: 50%;
    border: 3px solid rgb(216, 216, 216);
    box-sizing: border-box;
    margin: 0 auto;
}

.mainQuestionBlock_Number > div > span {
    color: rgb(216, 216, 216);
    font-size: 26px;
    position: relative;
    top: 2px;
}

.mainQuestionBlock_Questions {
    width: 85%;
}

.mainQuestionBlock_Question {
    font-size: 20px;
    margin-bottom: 20px;
}

.mainQuestionBlock_Answer {
    font-size: 18px;
    color: gray;
}

.mainQuestionBlock_Answer > a {
    white-space: nowrap;
}

.mainQuestionBlock_Question > span,
.mainQuestionBlock_Answer > span {
    font-weight: 700;
    color: rgb(255, 114, 0);
}