.main-slider {
  position: relative;
}

.main-slider__wrapper {
  height: 350px;
  box-sizing: border-box;
}

.main-slider .slick-track,
.main-slider .slick-list {
  height: 100%;
}

.main-slider__slide {
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.main-slider__slide-inner {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  height: 100%;
  padding-right: 100px;
}

.main-slider__slide-content {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.main-slider__large-string {
  color: #ff7200;
  font-size: 35px;
  font-weight: 800;
  line-height: 1;
  margin: 3px 0;
  text-shadow: 3px 3px 4px rgba(34, 60, 80, 0.2);
}

.main-slider__medium-string {
  color: #ff7200;
  font-size: 30px;
  font-weight: 600;
  line-height: 1;
  margin: 3px 0;
  text-shadow: 3px 3px 4px rgba(34, 60, 80, 0.2);
}

.main-slider__small-string {
  color: #fff;
  font-size: 25px;
  line-height: 1;
  margin: 3px 0;
  text-shadow: 3px 3px 4px rgba(34, 60, 80, 0.2);
}

.main-slider__link {
  color: #fff;
  font-size: 20px;
  background: #ff7200;
  border-radius: 5px;
  padding: 10px 20px;
  font-weight: 600;
  margin-top: 10px;
  transition: 0.2s ease-in-out background-color;
  box-shadow: 4px 4px 8px 0 rgba(34, 60, 80, 0.2);
}

.main-slider__link:hover {
  background: #ff8f3c;
}

.main-slider__arrow {
  display: block;
  position: absolute;
  width: 30px;
  height: 30px;
  padding: 0;
  background-color: #ff7200;
  transform: translateY(-50%);
  border: none;
  appearance: none;
  border-radius: 5px;
  background-repeat: no-repeat;
  background-size: 10px;
  cursor: pointer;
  transition: 0.2s ease-in-out background-color;
  box-shadow: 4px 4px 8px 0 rgba(34, 60, 80, 0.2);
}

.main-slider__arrow:hover {
  background-color: #ff8f3c;
}

.main-slider__arrow.slick-disabled {
  display: none;
}

.main-slider__arrow_prev {
  top: 50%;
  left: 35px;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 256 512'%3E%3Cpath fill='%23fff' d='M31.7 239l136-136c9.4-9.4 24.6-9.4 33.9 0l22.6 22.6c9.4 9.4 9.4 24.6 0 33.9L127.9 256l96.4 96.4c9.4 9.4 9.4 24.6 0 33.9L201.7 409c-9.4 9.4-24.6 9.4-33.9 0l-136-136c-9.5-9.4-9.5-24.6-.1-34z'/%3E%3C/svg%3E");
  background-position: 50%;
}

.main-slider__arrow_next {
  top: 50%;
  right: 35px;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 256 512'%3E%3Cpath fill='%23fff' d='M224.3 273l-136 136c-9.4 9.4-24.6 9.4-33.9 0l-22.6-22.6c-9.4-9.4-9.4-24.6 0-33.9l96.4-96.4-96.4-96.4c-9.4-9.4-9.4-24.6 0-33.9L54.3 103c9.4-9.4 24.6-9.4 33.9 0l136 136c9.5 9.4 9.5 24.6.1 34z'/%3E%3C/svg%3E%0A");
  background-position: 50%;
}

.main-slider__arrow-img {
  display: block;
  fill: #fff;
  width: 30px;
}

@media screen and (max-width: 1169px) {
  .main-slider__wrapper {
    height: 287px;
  }

  .main-slider__large-string {
    font-size: 30px;
  }

  .main-slider__medium-string {
    font-size: 25px;
  }

  .main-slider__small-string {
    font-size: 20px;
  }

  .main-slider__link {
    font-size: 16px;
  }
}

@media screen and (max-width: 959px) {
  .main-slider__wrapper {
    height: 229px;
  }

  .main-slider__slide-inner {
    padding-right: 80px;
  }

  .main-slider__arrow_prev {
    left: 25px;
  }

  .main-slider__arrow_next {
    right: 25px;
  }

  .main-slider__large-string {
    font-size: 25px;
  }

  .main-slider__medium-string {
    font-size: 20px;
  }

  .main-slider__small-string {
    font-size: 20px;
  }

  .main-slider__link {
    font-size: 16px;
  }
}

@media screen and (max-width: 767px) {
  .main-slider__wrapper {
    height: 170px;
  }

  .main-slider__slide {
    background-size: cover;
    background-position: center;
  }

  .main-slider__slide-inner {
    padding-right: 15px;
  }

  .main-slider__arrow {
    display: none !important;
  }

  .main-slider__large-string {
    font-size: 18px;
    margin: 1px 0;
  }

  .main-slider__medium-string {
    font-size: 16px;
    margin: 1px 0;
  }

  .main-slider__small-string {
    font-size: 16px;
    margin: 1px 0;
  }

  .main-slider__link {
    font-size: 14px;
    margin-top: 1px;
    padding: 8px 15px;
  }
}
