/* Меню */

.menu__background {
    background-color: rgb(71, 72, 72);
}

.mainMenu {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.linkCart {
    font-weight: 400;
    font-size: 22px;
    color: #ffffff;
    padding-left: 5px;
    padding-right: 5px;
    white-space: nowrap;
}

.pushRound {
    background-color: rgb(255, 114, 0);
    border-radius: 50px;
    font-family: "Circe", sans-serif;
    font-size: 16px;
    font-weight: 700;
    position: relative;
    bottom: 15px;
    left: -10px;
    width: 25px;
    height: 20px;
    display: inline-block;
    text-align: center;
    padding-top: 6px;
    color: #ffffff;
}

.logoBlock img {
    width: 100%;
}

.logoBlock {
    width: 100%;
    max-width: 200px;
}

.stringPosition {
    position: relative;
    bottom: 2px;
}

.hamburger-inner,
.hamburger-inner:after,
.hamburger-inner:before {
    background-color: #ffffff;
    height: 3px;
    width: 30px
}

.is-active .hamburger-inner,
.is-active .hamburger-inner:after,
.is-active .hamburger-inner:before {
    background-color: rgb(255, 114, 0);
}

.razdel {
    height: 1px;
    width: 100%;
    background-color: rgb(220, 220, 220);
    margin-bottom: 5px;
}

.search-popup-mob {
    display: none;
}

.topBar > ul > li > a {
    font-size: 20px;
}

@media screen and (min-width: 768px) {
    .topMenu > ul {
        list-style: none;
        display: flex;
        justify-content: space-between;
        width: 100%;
    }

    .topMenu > ul > li:first-child > a {
        color: rgb(255, 114, 0);
    }

    .topMenu > ul > li:first-child:hover > a {
        color: #ffffff;
    }

    .topMenu > ul > li > a {
        color: #ffffff;
        font-size: 22px;
        display: flex;
        justify-content: center;
    }

    .topMenu > ul > li:hover > a {
        color: rgb(255, 114, 0);
    }

    .topMenu > ul > li > a > i {
        font-size: 15px;
        position: absolute;
        top: 20px;
    }

    .topMenu > ul > li:hover > a > i {
        top: 30px;
    }

    .topMenu > ul > li > ul {
        list-style: none;
        position: absolute;
        top: 103px;
        z-index: 99;
        display: block;
        background-color: rgb(250, 250, 250);
        padding-right: 15px;
        padding-left: 5px;
        border-radius: 0 0 5px 5px;
        border-left: 2px solid;
        border-right: 2px solid;
        border-bottom: 2px solid;
        border-color: rgb(220, 220, 220);
        box-shadow: 0 3px 10px 2px rgba(0, 0, 0, 0.5);
        opacity: 0;
        visibility: hidden;
    }

    .topMenu > ul > li:hover > ul {
        opacity: 1;
        visibility: visible;
    }

    .topMenu > ul > li > ul > li {
        padding-top: 10px;
        padding-bottom: 10px;
        font-size: 18px;
    }

    .topMenu > ul > li > ul > li > a {
        white-space: nowrap;
        color: rgb(41, 41, 41);
        font-weight: 300;
    }

    .topMenu > ul > li > ul > li > a:hover {
        color: rgb(255, 114, 0);
    }

    .topMenu > ul > li > ul > li > a > i {
        display: inline-block;
        width: 32px;
        text-align: center;
        color: rgb(255, 114, 0);
    }

    .topMenu > ul > li {
        color: rgb(255, 114, 0);
        display: flex;
        justify-content: center;
        position: relative;
        padding-right: 10px;
        padding-top: 40px;
        padding-bottom: 40px;
    }

    .topMenu > ul > li:last-child {
        padding-right: 0;
    }

    .topMenu > ul > li:last-child > a {
        position: relative;
    }
}

@media screen and (max-width: 767px) {
    .topMenu {
        position: absolute;
        width: 100%;
        display: flex !important;
        flex-direction: column;
        top: 108px;
        background-color: black;
        left: -100%;
        -webkit-transition: all 0.3s ease;
        -moz-transition: all 0.3s ease;
        -o-transition: all 0.3s ease;
        transition: all 0.3s ease;
        padding-top: 15px;
        padding-bottom: 15px;
    }

    .topMenu-active {
        left: 0 !important;
    }

    .topMenu > ul a {
        display: block;
        padding: 15px;
    }

    .topMenu > ul > li > ul > li > a > i {
        display: none;
    }

    .topMenu > ul > li > a > i {
        display: none;
    }

    .topMenu > ul > li > ul > li > a {
        text-transform: uppercase;
    }

    .topMenu > ul a.topMenu-more-btn {
        display: none;
    }

    .search-popup-mob {
        display: block;
    }

}

@media screen and (min-width: 1024px) {

    .linkMenu:hover {
        color: rgb(255, 114, 0);
    }

    .linkCart:hover {
        color: rgb(255, 114, 0);
    }

    .linkCatalog:hover {
        color: #ffffff;
    }

}

@media screen and (min-width: 768px) and (max-width: 959px) {
    .topMenu > ul > li > a {
        font-size: 18px;
    }

    .logoBlock {
        max-width: 135px;
    }
}

.hamburger-inner:hover {
    cursor: pointer;
    opacity: 1;
}

@media screen and (min-width: 768px) {
    .mobCart {
        display: none !important;
    }
}

@media screen and (max-width: 767px) {
    .topBar a {
        padding: 5px;
    }

    .topBar ul {
        justify-content: center;
    }

    .topBar > ul > li > a {
        font-size: 20px;
        text-align: center;
        padding: 5px 0;
    }

    .topBar ul {
        flex-direction: column;
    }

    .cart a i {
        font-size: 30px;
    }

    .logoBlock {
        max-width: 120px;
    }

    .headTitle {
        height: 100px;
    }
}
