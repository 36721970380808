.tabs-linear {
    font-size: 16px;
}

.tabs-linear__controls {
    display: flex;
    justify-content: center;
}

.tabs-linear__tab {
    display: block;
    position: relative;
    padding: .75em 2.5em;
    cursor: pointer;
    user-select: none;
    color: black;
    background: #f7f7f7;
    font-size: 1em;
    font-weight: 600;
    border-top: 1px solid #dcdcdc;
    border-bottom: 1px solid #dcdcdc;
}

.tabs-linear__content {
    display: none;
    align-items: center;
    flex-direction: column;
    padding: 1.25em .25em;
    border-radius: 0;
    background: transparent;
}

.tabs-linear__content.active {
    display: flex;
}

.tabs-linear__label:first-of-type .tabs-linear__tab {
    border-left: 1px solid #dcdcdc;
    border-radius: 0.3125em 0 0 0.3125em;
}

.tabs-linear__label:last-of-type .tabs-linear__tab {
    border-right: 1px solid #dcdcdc;
    border-radius: 0 0.3125em 0.3125em 0;
}

.tabs-linear__radio {
    position: absolute;
    visibility: hidden;
    opacity: 0;
}

.tabs-linear__radio:checked + .tabs-linear__tab {
    position: relative;
    z-index: 1;
    cursor: default;
    color: white;
    border-color: transparent;
    background: var(--orange-lvl-1);
}

.tabs-linear__radio:not(:checked) + .tabs-linear__tab:hover {
    background: #f7f7f7;
}

.tabs-linear__radio:checked + .tabs-linear__tab:after{
    position: absolute;
    bottom: 0;
    content: "";
    transform: translate(-50%, 100%);
    pointer-events: none;
    border: 0.75em solid transparent;
    border-top-color: var(--orange-lvl-1);
}

.tabs-linear__tab_angle-left:after {
    left: 33%;
}

.tabs-linear__tab_angle-center:after {
    left: 50%;
}

.tabs-linear__tab_angle-right:after {
    left: 67%;
}
