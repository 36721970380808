.topBar {
    display: flex;
    justify-content: space-between;
}

.topBar > ul > li > a {
    display: block;
    font-size: 20px;
    font-family: "Proxima Nova", sans-serif;
    font-weight: 300;
    padding: 13px 0;
}

.topBar__background {
    position: relative;
    background-color: #292929;
}

.topBar > ul > li > a > i,
.cart a i {
    font-size: 24px;
}

.topBar__eMail {
    color: rgb(116, 115, 115);
}

.topBar > ul {
    list-style: none;
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.topBar > ul > li > a {
    color: rgb(255, 114, 0);

    font-size: 22px;
}

.topBar > ul > li:hover > a {
    color: #ffffff;
}

.topBar > ul > li > ul {
    list-style: none;
    position: absolute;
    z-index: 99;
    top: 53px;
    display: block;
    background-color: rgb(250, 250, 250);
    padding-right: 15px;
    padding-left: 5px;
    border-radius: 0 0 5px 5px;
    border-left: 2px solid;
    border-right: 2px solid;
    border-bottom: 2px solid;
    border-color: rgb(220, 220, 220);
    box-shadow: 0 3px 10px 2px rgba(0, 0, 0, 0.5);
    opacity: 0;
    visibility: hidden;
}

.topBar > ul > li:hover > ul {
    opacity: 1;
    visibility: visible;
}

.topBar > ul > li > ul > li {
    padding-top: 10px;
    padding-bottom: 10px;
    font-size: 18px;
}

.topBar > ul > li > ul > li > a {
    white-space: nowrap;
    color: rgb(41, 41, 41);
    font-weight: 300;
}

.topBar > ul > li > ul > li > a:hover {
    color: rgb(255, 114, 0);
}

.topBar > ul > li > ul > li > a > i {
    display: inline-block;
    width: 32px;
    text-align: center;
    color: rgb(255, 114, 0);
}

.topBar > ul > li {
    color: rgb(255, 114, 0);
    display: flex;
    justify-content: center;
    position: relative;
    padding-right: 10px;
}

.topBar > ul > li:last-child {
    padding-right: 0;
}

.topBar > ul > li:last-child > a {
    position: relative;
}