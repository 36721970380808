.mainBenefitsBlock {
    display: flex;
    margin-top: 35px;;
}

.benefitWrapper {
    width: 25%;
}

.benefitBlock {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.benefitBlock img {
    margin-bottom: 25px;
    height: 70px;
}

.benefitBlock h3 {
    text-align: center;
    font-size: 22px;
    font-weight: 700;
    margin-bottom: 20px;
}

.benefitBlock h3 span {
    display: block;
    text-align: center;
    text-transform: uppercase;
}

.benefitBlock p {
    width: 80%;
    text-align: center;
    margin-bottom: 25px;
}

.benefitBlock a {
    text-decoration: underline;
    font-size: 18px;
}