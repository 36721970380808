.calc-list {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 45px;
}

@media screen and (max-width: 767px) {
  .calc-list {
    padding: 15px;
  }
}
