.search-popup-wrapper {
    position: absolute;
    z-index: 0;
    width: 100%;
    top: 0;
    overflow: hidden;
    height: 52px;
}

.search-popup_active {
    z-index: 99;
}

.search-popup {
    width: 100%;
    position: absolute;
    z-index: 100;
    background: #ff7200;
    box-sizing: border-box;
    top: -52px;
}

.search-popup_active .search-popup {
    top: 0px;
}

.search-popup__form {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-sizing: border-box;
    padding-left: 15px;
    padding-right: 15px;
    height: 52px;
}

.search-popup__icon {
    color: #ffffff;
    font-size: 32px;
}

.search-popup__icon:hover {
    color: #ffb06f;
    cursor: pointer;
}

.search-popup__input {
    flex-grow: 2;
    background: transparent;
    border: none;
    border-bottom: 1px solid white;
    margin-left: 15px;
    margin-right: 15px;
    font-size: 20px;
    color: #ffffff;
    border-radius: 0;
}

.search-popup__input::placeholder {
    color: #ffb06f;
}

.search-popup__submit {
    display: block;
    background: transparent;
    color: #ffffff;
    font-size: 20px;
    border: none;
    border-radius: 50px;
}

.search-popup__submit:hover {
    cursor: pointer;
    color: #ffb06f;
}

@media screen and (max-width: 660px) {

    .search-popup__input {
        font-size: 16px;
        width: 100%;
    }
}